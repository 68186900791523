import { FullScreenLoader } from 'components/loader';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarMilkerQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useMemo } from 'react';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import parseFunction from './parser';

function Delpro() {
  const { columns, onColumnsViewChange } = useColumns('columnsDelpro');

  const { data: deviceData = [], isFetching: isDeviceDataFetching } = useCalendarMilkerQuery(useGetDeviceDataQuery);

  const dataDelpro = useMemo(() => parseFunction(deviceData), [deviceData]);

  return (
    <>
      {isDeviceDataFetching && <FullScreenLoader />}
      <ViewHeader title="DELPRO" dateSelector="range" />
      <TableView
        title="DELPRO"
        data={dataDelpro}
        columns={columns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          pagination: false,
          filter: true,
        }}
      />
    </>
  );
}

export default Delpro;
