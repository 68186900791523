import Button from 'components/button';
import Icon from 'components/icons';
import { useAdminUser } from 'components/protected-view/useAdminUser';
import TareasPendientesBanner from 'components/tareas-pendientes-banner';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useColumns } from 'hooks/useColumns';
import { useModal } from 'hooks/useModal';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { useDeleteGacUserMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { PumpUser } from 'utils/device';
import { ModalEditarUsuario } from './ModalEditarUsuario';

export function GacAdminUsuario() {
  const isAdminUser = useAdminUser();

  const deviceSelected = useSelector(selectDeviceSelected);
  const users = deviceSelected?.pumpUsers || [];

  const { columns, onColumnsViewChange } = useColumns('columnsGacUsers');
  const [isOpenEditarUsuario, onOpenEditarUsuario, onCloseEditarUsuario, usuarioToEdit] = useModal<PumpUser>();

  const [deleteGacUser] = useDeleteGacUserMutation();

  const onClickEliminarUser = (user: PumpUser) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmEliminar = confirm(
      `¿Está seguro que desea eliminar el usuario ${user.name}? Esta operación no se puede revertir.`
    );
    if (confirmEliminar) deleteGacUser({ user, serialNumber: deviceSelected!.serialNumber });
  };

  const interactiveColumns = cloneDeep(columns);
  if (isAdminUser) {
    interactiveColumns.unshift({
      name: 'editar',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (_value: any, tableMeta: any) => {
          const userIndex = tableMeta.rowIndex;
          const user = users[userIndex];
          return (
            <div>
              <Button
                variant="icon"
                onClick={() => {
                  onOpenEditarUsuario(user);
                }}
              >
                <Icon name="pencil" size="s" />
              </Button>
            </div>
          );
        },
      },
    });

    // Add last column with delete button
    interactiveColumns.push({
      name: 'eliminar',
      label: 'Eliminar',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (_value: any, tableMeta: any) => {
          const userIndex = tableMeta.rowIndex;
          const user = users[userIndex];
          return (
            <div className="w-full flex justify-center items-center">
              <Button
                variant="icon"
                onClick={() => {
                  onClickEliminarUser(user);
                }}
              >
                <Icon name="trash" size="m" />
              </Button>
            </div>
          );
        },
      },
    });
  }

  return (
    <>
      {isOpenEditarUsuario && (
        <ModalEditarUsuario isOpen={isOpenEditarUsuario} onClose={onCloseEditarUsuario} usuario={usuarioToEdit} />
      )}
      <ViewHeader
        title="Usuarios"
        action={{
          label: 'Crear Usuario',
          onClick: () => onOpenEditarUsuario(null),
          disabled: users?.length >= 20,
          isProtected: true,
        }}
      />
      <div className="px-8">
        <TareasPendientesBanner />
      </div>
      <TableView
        title="Usuarios"
        data={users || []}
        columns={interactiveColumns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          print: false,
          download: false,
          filter: false,
          viewColumns: false,
          pagination: false,
        }}
      />
    </>
  );
}
