import Table from 'components/table/Table';
import { useColumns } from 'hooks/useColumns';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { descargasActions, selectDescargaEditing } from 'store/descargas/descargasSlice';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';

function TablaLotes() {
  const dispatch = useDispatch();
  const deviceSelected = useSelector(selectDeviceSelected);
  const { lots: descargaEditingLots = [] } = useSelector(selectDescargaEditing);
  const { columns: columnsLotes } = useColumns(
    isDeviceVersionAtLeast(VERSION.TWO, deviceSelected) ? 'columnsLotesV2' : 'columnsLotes'
  );

  const lotsInDevice = useMemo(
    () => (deviceSelected?.lots || []).filter((lot) => lot.id) || [],
    [deviceSelected?.lots]
  );

  const descargaEditingLotsNombres = useMemo(() => {
    return descargaEditingLots.filter((lot) => !lot.deleted).map((lot) => lot.name);
  }, [descargaEditingLots]);

  const lotesSelected = useMemo(
    () =>
      lotsInDevice.reduce(function (accumulated, current, index) {
        if (descargaEditingLotsNombres.includes(current.name)) accumulated.push(index);
        return accumulated;
      }, [] as number[]),
    [lotsInDevice, descargaEditingLotsNombres]
  );

  const onRowsSelect = (currentRowsSelected: any[]) => {
    if (currentRowsSelected.length === 0)
      lotesSelected.forEach((currentRow) => dispatch(descargasActions.toggleLote(lotsInDevice[currentRow])));
    else
      currentRowsSelected.forEach((currentRow) =>
        dispatch(descargasActions.toggleLote(lotsInDevice[currentRow.dataIndex]))
      );
  };
  return (
    <Table
      title="Lotes"
      columns={columnsLotes}
      data={lotsInDevice}
      options={{
        pagination: false,
        onRowsSelect,
        disableToolbarSelect: true,
        selectableRows: 'multiple',
        filter: false,
      }}
      rowsSelected={lotesSelected}
    />
  );
}
export default memo(TablaLotes);
