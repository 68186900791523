import { FullScreenLoader } from 'components/loader';
import Table from 'components/table/Table';
import Card from 'design-system/card';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useMemo, useState } from 'react';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { redondearDosDecimales } from 'utils/helpers/math';
import parseFunction from './parser';

export function GacHistorico() {
  const { data: deviceData = [], isFetching: isDeviceDataFetching } = useCalendarQuery(useGetDeviceDataQuery);

  const [usersFilter, setUsersFilter] = useState<string[]>([]);
  const [vehiculosFilter, setVehiculosFilter] = useState<string[]>([]);

  const { columns, onColumnsViewChange } = useColumns('columnsGACHistorico');
  const dataGACHistorico = useMemo(() => parseFunction(deviceData), [deviceData]);

  /* const dataGACHistoricoIds = useMemo(() => dataGACHistorico.map((item) => item.id), [dataGACHistorico]);

  // Sort the dataGACHistoricoIds and get a list of every ID missing between the first and last ID
  const missingIds = useMemo(() => {
    const sortedIds = dataGACHistoricoIds.sort((a, b) => a - b);
    const firstId = sortedIds[0];
    const lastId = sortedIds[sortedIds.length - 1];
    const missingIds = [];
    for (let i = firstId; i <= lastId; i++) {
      if (!sortedIds.includes(i)) missingIds.push(i);
    }
    return missingIds;
  }, [dataGACHistoricoIds]);

 useEffect(() => {
    if (missingIds?.length > 0)
      missingIds.forEach((id) => {
        // Mostrar un warning por cada ID faltante
        toast.warn(`Error en el dato ${id}: no se encontró en la base de datos.`, {
          position: 'bottom-right',
        });
      });
  }, [missingIds]); */

  const ltTotales = useMemo(
    () =>
      dataGACHistorico
        .filter((item) => usersFilter.length === 0 || usersFilter.includes(item.user))
        .filter((item) => vehiculosFilter.length === 0 || vehiculosFilter.includes(item.vehicle))
        .reduce((acc, item) => {
          const ltDownload = item.ltDownload || 0;
          if (ltDownload > 0) return acc + ltDownload;
          else return acc;
        }, 0),
    [dataGACHistorico, usersFilter, vehiculosFilter]
  );

  return (
    <>
      {isDeviceDataFetching && <FullScreenLoader />}
      <ViewHeader title="Histórico Cargas" dateSelector="range" maxDateRangeInDays={31} />

      <div className="px-8 py-8">
        <Card>
          <div className="border-b border-b-white/20">
            <Table
              title="Valores históricos"
              data={dataGACHistorico}
              columns={columns}
              onColumnViewChange={onColumnsViewChange}
              options={{
                pagination: false,
                filter: true,
                onFilterChange: (
                  changedColumn: any,
                  filterList: any,
                  type: any,
                  changedColumnIndex: any,
                  rowIndex: any
                ) => {
                  if (changedColumn === 'user' && type === 'checkbox') setUsersFilter(filterList[changedColumnIndex]);
                  if (changedColumn === 'vehicle' && type === 'checkbox')
                    setVehiculosFilter(filterList[changedColumnIndex]);
                },
              }}
            />
          </div>
          <div className="flex flex-col gap-3 p-8">
            <h3 className="text-xl font-bold leading-6">
              Total: {redondearDosDecimales(ltTotales)} litros despachados
            </h3>
          </div>
        </Card>
      </div>
    </>
  );
}
