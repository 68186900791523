import { useSelector } from 'react-redux';
import { selectDevices } from 'store/config/configSlice';
import {
  selectDeviceassociatedMilker,
  selectDeviceSelectedSerialNumber,
  selectDeviceSelectedStockId,
  selectMaxDate,
  selectMinDate,
} from 'store/devices/devicesSlice';

export function useCommonQueryParameters() {
  const devices = useSelector(selectDevices);
  const serialNumber = useSelector(selectDeviceSelectedSerialNumber) || '';
  const selectedDeviceStockId = useSelector(selectDeviceSelectedStockId) || '';
  const selectDeviceAfimilk = useSelector(selectDeviceassociatedMilker) || '';
  const selectDeviceMilker = useSelector(selectDeviceassociatedMilker);
  const minDate = useSelector(selectMinDate);
  const maxDate = useSelector(selectMaxDate);

  const stockIdFromSelectedEquipo = devices?.find((device) => device.serialNumber === serialNumber)?.sipnStock?.id;
  const devicesWithSameStockIdAsSelectedDevice = devices?.filter(
    (device) => device.sipnStock?.id === stockIdFromSelectedEquipo
  );

  return {
    devices,
    serialNumber,
    selectedDeviceStockId,
    selectDeviceAfimilk,
    selectDeviceMilker,
    minDate,
    maxDate,
    devicesWithSameStockIdAsSelectedDevice,
  };
}
