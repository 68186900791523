import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ChipToggle from 'design-system/chip-toggle';
import ViewHeader from 'design-system/view-header';
import { useCalendarQueryDevicesFromSameStock } from 'hooks/useCalendarQuery';
import { useToggle } from 'hooks/useToggle';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectDescargas } from 'selectors/descargas.selectors';
import { selectRecetas } from 'selectors/recetas.selectors';
import { useGetDevicesDownloadDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelectedStockIngredients } from 'store/devices/devicesSlice';
import { isTrue } from 'utils/helpers/validation';
import parseFunction from './parser';

function LotesHistorico() {
  const { data: deviceDownloadData = [], isFetching: isDeviceDownloadDataFetching } =
    useCalendarQueryDevicesFromSameStock(useGetDevicesDownloadDataQuery);

  const descargas = useSelector(selectDescargas);
  const recetas = useSelector(selectRecetas);
  const ingredientes = useSelector(selectDeviceSelectedStockIngredients);
  const [filterNotLoaded, toggleFilterNotLoaded] = useToggle(false);

  const customToolbar = useCallback(
    () => (
      <div className="inline-block">
        <ChipToggle
          label={`Filtrar no cargados`}
          onToggle={toggleFilterNotLoaded}
          toggleValue={isTrue(filterNotLoaded)}
          noBorder
        />
      </div>
    ),
    [filterNotLoaded, toggleFilterNotLoaded]
  );

  return (
    <>
      {isDeviceDownloadDataFetching && <FullScreenLoader />}
      <ViewHeader title="Histórico de Lotes" dateSelector="range" maxDateRangeInDays={35} />
      <TableSubsumView
        title="Histórico de Lotes"
        data={deviceDownloadData}
        columnsName={'columnsHistoricoLotes'}
        tableProps={{
          withSelect: false,
          tableOptions: { customToolbar },
        }}
        parseFunction={parseFunction}
        extraParseFunctionArgs={[descargas, filterNotLoaded, recetas, ingredientes]}
      />
    </>
  );
}

export default LotesHistorico;
