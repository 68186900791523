import Button from 'components/button';
import Icon from 'components/icons';
import Input from 'components/input';
import { useAdminUser } from 'components/protected-view/useAdminUser';
import TareasPendientesBanner from 'components/tareas-pendientes-banner';
import FormCard from 'design-system/form-card';
import TableView from 'design-system/table-view';
import { TableRowTwoCols } from 'design-system/table/table-row';
import ViewHeader from 'design-system/view-header';
import { useColumns } from 'hooks/useColumns';
import { useModal } from 'hooks/useModal';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useUpdateGacAddFuelMutation, useUpdateGacChangePriceMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { PumpVehicle } from 'utils/device';
import { ModalEditarVehiculo } from './ModalEditarVehiculo';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';

interface GacCombustible {
  fuelAdded: number;
  fuelPrice: number;
}

export function GacAdminVehiculos() {
  const isAdminUser = useAdminUser();

  const deviceSelected = useSelector(selectDeviceSelected);
  const pumpVehicles = deviceSelected?.pumpVehicles || [];

  const [addFuel] = useUpdateGacAddFuelMutation();
  const [changeFuelPrice] = useUpdateGacChangePriceMutation();

  const { columns, onColumnsViewChange } = useColumns('columnsGacVehicles');

  const isDeviceV2 = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);

  const columnsWithKeyId = isDeviceV2
    ? [
        ...columns,
        {
          name: 'keyId',
          label: 'ID Tarjeta Llavero',
          options: { filter: false, sort: true },
        },
      ]
    : columns;

  const [isOpenEditarVehiculo, onOpenEditarVehiculo, onCloseEditarVehiculo, vehiculoToEdit] = useModal<PumpVehicle>();

  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<GacCombustible>({
    mode: 'onChange',
    defaultValues: {
      fuelAdded: 0,
      fuelPrice: deviceSelected?.fuelPrice || 0,
    },
  });

  useEffect(() => {
    if (deviceSelected) {
      setValue('fuelPrice', deviceSelected?.fuelPrice || 0);
    }
  }, [deviceSelected, setValue, deviceSelected?.fuelPrice]);

  const onSaveChanges = () => {
    handleSubmit(async ({ fuelAdded, fuelPrice }) => {
      setIsSaving(true);
      if (fuelAdded > 0) await addFuel({ fuel: String(fuelAdded), serialNumber: deviceSelected!.serialNumber });
      if (fuelPrice > 0 && fuelPrice !== deviceSelected?.fuelPrice)
        await changeFuelPrice({ price: String(fuelPrice), serialNumber: deviceSelected!.serialNumber });

      reset({
        fuelAdded: 0,
        fuelPrice: deviceSelected?.fuelPrice || 0,
      });
      setIsSaving(false);
    })();
  };

  const interactiveColumns = cloneDeep(columnsWithKeyId);
  if (isAdminUser) {
    interactiveColumns.unshift({
      name: 'editar',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (_value: any, tableMeta: any) => {
          const userIndex = tableMeta.rowIndex;
          const vehiculo = pumpVehicles[userIndex];
          return (
            <div>
              <Button
                variant="icon"
                onClick={() => {
                  onOpenEditarVehiculo(vehiculo);
                }}
              >
                <Icon name="pencil" size="s" />
              </Button>
            </div>
          );
        },
      },
    });
  }

  return (
    <>
      {isOpenEditarVehiculo && (
        <ModalEditarVehiculo isOpen={isOpenEditarVehiculo} onClose={onCloseEditarVehiculo} vehiculo={vehiculoToEdit} />
      )}
      <ViewHeader title="Vehículos" />
      <div className="px-8">
        <TareasPendientesBanner />
      </div>
      <div className="px-8">
        <FormCard
          title="Combustible"
          isCancelDisabled={!isDirty || isSaving}
          isSaveDisabled={!isDirty || isSaving}
          onCancel={() => {
            reset({
              fuelAdded: 0,
              fuelPrice: deviceSelected?.fuelPrice || 0,
            });
          }}
          onSave={onSaveChanges}
          items={[
            {
              value: (
                <TableRowTwoCols className="!items-start">
                  <Controller
                    name="fuelAdded"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex flex-col gap-2">
                        <Input
                          placeholder="Ingreso combustible"
                          label="Ingreso combustible"
                          value={value}
                          onChange={onChange}
                          type="number"
                          min={0}
                        />
                        {/* Show current fuel value */}
                        <span className="ml-1">Combustible disponible: {deviceSelected?.availableFuelLiters}L</span>
                      </div>
                    )}
                  />
                  <Controller
                    name="fuelPrice"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex flex-col gap-2">
                        <Input
                          placeholder="Precio combustible"
                          label="Precio combustible"
                          value={value}
                          onChange={onChange}
                          type="number"
                          min={0}
                        />
                      </div>
                    )}
                  />
                </TableRowTwoCols>
              ),
            },
          ]}
        />
      </div>

      <TableView
        title="Vehículos"
        data={pumpVehicles || []}
        columns={interactiveColumns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          print: false,
          download: false,
          filter: false,
          viewColumns: false,
          pagination: false,
        }}
      />
    </>
  );
}
