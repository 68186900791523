import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useSelector } from 'react-redux';
import { useGetDeviceDownloadDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';
import parseFunction from './parser';

function Descargas() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { data: deviceDownloadData = [], isFetching: isDeviceDownloadDataFetching } =
    useCalendarQuery(useGetDeviceDownloadDataQuery);

  const columnsName = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected) ? 'columnsDescargasV5' : 'columnsDescargas';

  return (
    <>
      {isDeviceDownloadDataFetching && <FullScreenLoader />}
      <ViewHeader title="Descargas" dateSelector="range" />
      <TableSubsumView
        title="Descargas"
        data={deviceDownloadData}
        columnsName={columnsName}
        parseFunction={parseFunction}
        extraParseFunctionArgs={[false]}
      />
    </>
  );
}

export default Descargas;
