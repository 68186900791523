import { useCustomCalendarQuery } from 'hooks/useCalendarQuery';
import { useMemo } from 'react';
import parseDataDescargas from 'screens/descargas/parser';
import { useGetDeviceDownloadDataQuery } from 'store/devices/devicesApi';
import { today, twoDaysAgo } from 'utils/helpers';
import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const useThreeDayDescargas = () => {
  const { data: deviceDownloadData = [] } = useCustomCalendarQuery(useGetDeviceDownloadDataQuery, twoDaysAgo, today);
  const descargasData = useMemo(() => parseDataDescargas(deviceDownloadData, {}, false), [deviceDownloadData]);
  const descargasPoints = useMemo(
    () =>
      descargasData?.[0]
        .filter((item) => item.rowType !== 'resultado')
        .filter((item: any) => item.porcentajeKg > -90)
        .map((item) => ({ ...item, tipo: 'Descarga', formattedDate: customBodyRenderDate((item as any).date) })),
    [descargasData]
  );

  return descargasPoints || [];
};
