import { FullScreenLoader } from 'components/loader';
import FormCard from 'design-system/form-card';
import SidebarSlide from 'design-system/sidebar-slide';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectDescargas } from 'selectors/descargas.selectors';
import { useAddUnloadGuideMutation, useUpdateUnloadGuideMutation } from 'store/descargas/descargasApi';
import { resetDescarga, selectDescargaEditing } from 'store/descargas/descargasSlice';
import { useGetDeviceTasksQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import store from 'store/store';
import { UnloadGuide } from 'utils/device/lot';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';
import TablaDescarga from '../tabla-descarga';
import DescargaForm from './descarga-form';
import TablaLotes from './tabla-lotes';

function ModalAgregarDescarga({
  isOpen,
  onClose = () => {},
  setIsSavingData = () => {},
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsSavingData?: (isSavingData: boolean) => void;
}) {
  const dispatch = useDispatch();
  const [onAddUnloadGuide, { isLoading: isAddUnloadGuideLoading }] = useAddUnloadGuideMutation();
  const [onUpdateUnloadGuide, { isLoading: isUpdateUnloadGuideLoading }] = useUpdateUnloadGuideMutation();

  const deviceSelected = useSelector(selectDeviceSelected);
  const getDeviceTasks = useGetDeviceTasksQuery({ serialNumber: deviceSelected?.serialNumber || '' });

  // Obtener todos los nombres de descargas y verificar si ya existe uno con el mismo nombre
  const descargas = useSelector(selectDescargas) || [];
  const descargasNames = descargas.map((descarga) => descarga.name);

  const { watch, getValues } = useFormContext<UnloadGuide>();
  const [name] = watch(['name']);

  const canGuardar = name !== '';

  const handleClose = useCallback(() => {
    dispatch(resetDescarga());
    onClose();
  }, [dispatch, onClose]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const descargaNombre = useMemo(() => name, [isOpen]); // deshabilitar warning porque es para que no se cambie el titulo del modal con cada cambio del nombre de la descarga

  const onSave = useCallback(async () => {
    const { name, associatedRecipe, unloadType, leftoverFood, unloadSchedule } = getValues();

    if (!name) {
      toast.error('Por favor, complete todos los campos.');
      return;
    }

    if (descargaNombre !== name && descargasNames?.includes(name)) {
      toast.error('Ya existe una descarga con ese nombre.');
      return;
    }

    const descargaEditingFromStore = store.getState().descargas.descargaEditing;

    const newDescarga = parseDescargaBeforeSending({
      ...descargaEditingFromStore,
      name,
      associatedRecipe,
      unloadType,
      leftoverFood,
      unloadSchedule,
    });

    if (!newDescarga?.lotIds?.length) {
      toast.error('Por favor, seleccione al menos un lote.');
      return;
    }

    setIsSavingData(true);

    const promise = !descargaNombre
      ? onAddUnloadGuide({
          descarga: newDescarga,
          serialNumber: deviceSelected?.serialNumber || '',
        })
      : onUpdateUnloadGuide({
          descarga: newDescarga,
          serialNumber: deviceSelected?.serialNumber || '',
        });

    const onSuccess = () => {
      getDeviceTasks.refetch();
      handleClose();
    };

    await mutateWithToast(promise, onSuccess, {
      pending: 'Guardando descarga...',
      success: 'Descarga guardada correctamente.',
      error: 'Error al guardar la descarga.',
    });
  }, [
    descargaNombre,
    descargasNames,
    deviceSelected?.serialNumber,
    getDeviceTasks,
    getValues,
    handleClose,
    onAddUnloadGuide,
    onUpdateUnloadGuide,
    setIsSavingData,
  ]);

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={handleClose}
      title={!descargaNombre ? 'Nueva descarga' : `Editar "${descargaNombre}"`}
      actions={[
        {
          autoFocus: false,
          onClick: handleClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
          disabled: !canGuardar,
        },
      ]}
    >
      {(isAddUnloadGuideLoading || isUpdateUnloadGuideLoading) && <FullScreenLoader />}
      <FormCard
        items={[
          {
            value: <DescargaForm editingEnabled />,
          },
          {
            value: <TablaLotes />,
          },
          {
            value: <TablaDescargaForm />,
          },
        ]}
      />
    </SidebarSlide>
  );
}
export default ModalAgregarDescarga;

function TablaDescargaForm() {
  const descargaEditing = useSelector(selectDescargaEditing);

  return <TablaDescarga descarga={descargaEditing} editable />;
}

function parseDescargaBeforeSending({ lots = [], ...values }: UnloadGuide): UnloadGuide {
  // Get the new lotIds
  const lotIds = lots.filter((lot) => !lot.deleted).map((lot) => lot.id) || [];

  // Calculate the lotsMetadata: add the missing lots and remove the deleted lots
  const newLotsMetadata = lotIds.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: { elaborationPercentage: values.lotsMetadata?.[curr]?.elaborationPercentage || 100 },
    }),
    {}
  );

  return {
    ...values,
    lotsMetadata: newLotsMetadata,
    lotIds,
  };
}
