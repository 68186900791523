import { IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Input from 'components/input';
import LiteTable from 'components/lite-table';
import TableSubsum from 'components/table/TableSubsum';
import { useColumns } from 'hooks/useColumns';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { descargasActions } from 'store/descargas/descargasSlice';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { Lot, UnloadGuide } from 'utils/device/lot';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';
import parseDataCreadorDescargas, { UnloadGuideFormatted } from './parser';

const TablaDescarga = ({
  descarga,
  lite,
  editable,
  onRowClick,
}: {
  descarga: UnloadGuide;
  lite?: boolean;
  editable?: boolean;
  onRowClick?: (row: UnloadGuideFormatted) => void;
}) => {
  const dispatch = useDispatch();
  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceV2 = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);
  const { columns: columnsCreadorDescarga, onColumnsViewChange } = useColumns(
    isDeviceV2 ? 'columnsTablaCreadorDescargasV2' : 'columnsTablaCreadorDescargas'
  );

  const associatedRecipeData = deviceSelected?.recipes?.find((recipe) => recipe.id === descarga.associatedRecipe);

  const tableData = useMemo(
    () =>
      parseDataCreadorDescargas(
        {
          ...descarga,
          associatedRecipeData,
        },
        isDeviceV2
      ),
    [associatedRecipeData, descarga, isDeviceV2]
  );

  const onPercentageElaboracionEdit = (tableMeta: any, newValue: number) => {
    const lotId = (tableData[tableMeta.rowIndex] as Lot).id;
    const lotMetadata = (descarga.lotsMetadata || {})[lotId];
    dispatch(
      descargasActions.onDescargaFormChange({
        ...descarga,
        lotsMetadata: {
          ...descarga.lotsMetadata,
          [lotId]: {
            ...lotMetadata,
            elaborationPercentage: newValue,
          },
        },
      })
    );
  };

  if (isDeviceV2 && onRowClick && !editable) {
    columnsCreadorDescarga[2].options['customBodyRender'] = (value: any, tableMeta: any) => {
      return (
        <div>
          {value}
          <button
            onClick={() => onRowClick(tableData[tableMeta.rowIndex])}
            className="flex items-center w-fit whitespace-nowrap text-sm px-2 bg-haasten-light/20 rounded my-1"
          >
            Ver Receta
          </button>
        </div>
      );
    };
  }

  if (isDeviceV2 && editable) {
    // columna % Elaboracion as Input
    columnsCreadorDescarga[5].options['customBodyRender'] = (value: any, tableMeta: any, updateValue: any) => {
      return tableMeta.rowIndex < tableData.length - 1 ? (
        <Input
          type="number"
          onBlur={(value) => {
            onPercentageElaboracionEdit(tableMeta, Number(value));
            updateValue(value);
          }}
          onChange={(value) => updateValue(value)}
          value={value}
        />
      ) : (
        <p>{value}</p>
      );
    };

    columnsCreadorDescarga[0].options['customBodyRender'] = (
      // Columna orden
      _value: any,
      tableMeta: any
    ) => {
      if (tableMeta.rowIndex < tableData.length - 1) {
        return (
          <div>
            {tableMeta.rowIndex > 0 && (
              <IconButton
                aria-label="ordenar-arriba"
                size="small"
                onClick={() => dispatch(descargasActions.onMoveDescargaRowUp({ rowIndex: tableMeta.rowIndex }))}
              >
                <ArrowUpwardIcon fontSize="inherit" />
              </IconButton>
            )}
            {tableMeta.rowIndex < tableData.length - 2 && (
              <IconButton
                aria-label="ordenar-abajo"
                size="small"
                onClick={() => dispatch(descargasActions.onMoveDescargaRowDown({ rowIndex: tableMeta.rowIndex }))}
              >
                <ArrowDownwardIcon fontSize="inherit" />
              </IconButton>
            )}
          </div>
        );
      }
    };
  }

  return lite ? (
    <LiteTable data={tableData} columns={columnsCreadorDescarga} />
  ) : (
    <TableSubsum
      title={'Descarga'}
      data={tableData}
      columnas={columnsCreadorDescarga}
      onColumnsViewChange={onColumnsViewChange}
      withSelect={false}
      tableOptions={{
        pagination: false,
        filter: false,
      }}
    />
  );
};

export default memo(TablaDescarga);

export function getRecetaMVTotal(descarga: UnloadGuideFormatted[]): number {
  if (descarga.length > 0 && descarga[descarga.length - 1].rowType === 'resultado') {
    let lastRow = descarga[descarga.length - 1];
    return Array.isArray(lastRow.kgLotMV) ? (lastRow.kgLotMV as Array<number>)[1] : (lastRow.kgLotMV as number);
  }
  return 0;
}
