import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCommonQueryParameters } from 'hooks/useCommonQueryParameters';
import { aMonthAgo, threeDaysAgo, today, todayStart } from 'utils/helpers';

export function useCalendarQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { serialNumber, minDate, maxDate } = useCommonQueryParameters();
  return fn(
    {
      serialNumber,
      minDate: minDate,
      maxDate: maxDate,
    },
    {
      skip: !serialNumber || extraSkip,
    }
  );
}

export function useCalendarQueryDevicesFromSameStock<T>(
  fn: UseQuery<QueryDefinition<any, any, any, T, any>>,
  extraSkip?: boolean
) {
  const { devices, serialNumber, minDate, maxDate } = useCommonQueryParameters();
  const stockIdFromSelectedEquipo = devices?.find((device) => device.serialNumber === serialNumber)?.sipnStock?.id;
  const serialNumbers = devices
    ?.filter((device) => device.sipnStock?.id === stockIdFromSelectedEquipo)
    .map((device) => device.serialNumber);
  return fn(
    {
      serialNumbers,
      minDate: minDate,
      maxDate: maxDate,
    },
    {
      skip: !serialNumber || extraSkip,
    }
  );
}

export function useCalendarStockQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { selectedDeviceStockId, minDate, maxDate } = useCommonQueryParameters();
  return fn(
    {
      serialNumber: selectedDeviceStockId,
      minDate: minDate,
      maxDate: maxDate,
    },
    {
      skip: !selectedDeviceStockId || extraSkip,
    }
  );
}

export function useCalendarAfimilkQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { selectDeviceMilker, minDate, maxDate } = useCommonQueryParameters();
  return fn(
    {
      serialNumber: selectDeviceMilker?.serialNumber,
      minDate: minDate,
      maxDate: maxDate,
    },
    {
      skip: !selectDeviceMilker?.serialNumber || extraSkip,
    }
  );
}

export function useCalendarMilkerQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { selectDeviceMilker, minDate, maxDate } = useCommonQueryParameters();
  return fn(
    {
      serialNumber: selectDeviceMilker?.serialNumber,
      minDate: minDate,
      maxDate: maxDate,
    },
    {
      skip: !selectDeviceMilker?.serialNumber || extraSkip,
    }
  );
}

export function useLastMonthQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { serialNumber } = useCommonQueryParameters();
  return fn(
    {
      serialNumber,
      minDate: aMonthAgo,
      maxDate: today,
    },
    {
      skip: !serialNumber || extraSkip,
    }
  );
}

export function useLastFourDaysQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { serialNumber } = useCommonQueryParameters();
  return fn(
    {
      serialNumber,
      minDate: threeDaysAgo,
      maxDate: today,
    },
    {
      skip: !serialNumber || extraSkip,
    }
  );
}

export function useTodayQuery<T>(fn: UseQuery<QueryDefinition<any, any, any, T, any>>, extraSkip?: boolean) {
  const { serialNumber } = useCommonQueryParameters();
  return fn(
    {
      serialNumber,
      minDate: todayStart,
      maxDate: today,
    },
    {
      skip: !serialNumber || extraSkip,
    }
  );
}

export function useCustomCalendarQuery<T>(
  fn: UseQuery<QueryDefinition<any, any, any, T, any>>,
  minDate: string,
  maxDate: string,
  extraSkip?: boolean
) {
  const { serialNumber } = useCommonQueryParameters();
  return fn(
    {
      serialNumber,
      minDate,
      maxDate,
    },
    {
      skip: !serialNumber || extraSkip,
    }
  );
}
