import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import Select from 'design-system/select';
import ViewHeader from 'design-system/view-header';
import { useCalendarMilkerQuery, useCalendarQueryDevicesFromSameStock } from 'hooks/useCalendarQuery';
import { useToggle } from 'hooks/useToggle';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDescargas } from 'selectors/descargas.selectors';
import { selectRecetas } from 'selectors/recetas.selectors';
import { useGetDeviceDataQuery, useGetDevicesDownloadDataQuery } from 'store/devices/devicesApi';
import {
  selectDeviceassociatedMilker,
  selectDeviceSelected,
  selectDeviceSelectedStockIngredients,
} from 'store/devices/devicesSlice';
import { DEVICE_TYPE_AFIMILK, DEVICE_TYPE_DELPRO } from 'utils/device';
import {
  DeviceEventAfimilk,
  DeviceEventDelpro,
  DeviceEventDownload,
  filterAndSortByMilker,
} from 'utils/device/event/sip';
import { customBodyRenderDateOnly } from 'utils/helpers/body-renders';
import { redondearDosDecimales } from 'utils/helpers/math';
import parseFunction, { DeviceEventDownloadFormatted, DeviceEventDownloadSummed } from '../lotes-historico/parser';
import { ReporteLotesGrafico } from './ReporteLotesGrafico';
import { isDeviceVersionAtLeast, VERSION } from 'utils/helpers/validation';

export default function ReporteLotes() {
  const { data: deviceDownloadData = [], isFetching: isDeviceDownloadDataFetching } =
    useCalendarQueryDevicesFromSameStock(useGetDevicesDownloadDataQuery);

  // Obtener datos del dispositivo seleccionado
  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionSix = isDeviceVersionAtLeast(VERSION.SIX, deviceSelected);
  const tableColumns = isDeviceVersionSix ? 'columnsReporteLotesV2' : 'columnsReporteLotes';

  // Obtener datos del milker asociado
  const selectDeviceMilker = useSelector(selectDeviceassociatedMilker);
  const isAFIMILK = selectDeviceMilker?.type === DEVICE_TYPE_AFIMILK;
  const isDELPRO = selectDeviceMilker?.type === DEVICE_TYPE_DELPRO;
  const { data: milkerDeviceData = [], isFetching: isMilkerDeviceDataFetching } =
    useCalendarMilkerQuery(useGetDeviceDataQuery);

  const isFetching = isDeviceDownloadDataFetching || isMilkerDeviceDataFetching;

  const descargas = useSelector(selectDescargas);
  const recetas = useSelector(selectRecetas);
  const ingredientes = useSelector(selectDeviceSelectedStockIngredients);
  const lotsInDevice = deviceSelected?.lots;
  const [filterNotLoaded, toggleFilterNotLoaded] = useToggle(false);

  // Group all download data by lot
  const groupedDeviceDownloadDataByLot = groupDeviceDownloadDataByLot(deviceDownloadData);

  // Get all the lots
  const lots = useMemo(() => Object.keys(groupedDeviceDownloadDataByLot), [groupedDeviceDownloadDataByLot]);

  // Keep track of the selected lot
  const [lotSelected, setLotSelected] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (lots.length === 0 || lotSelected !== undefined) return;
    setLotSelected(lots[0]); // Initial lot
  }, [lotSelected, lots]);

  // Get the associatedMilkerIndex for the selected lot
  const associatedMilkerIndex = useMemo(
    () => lotsInDevice?.find((lot) => lot.name === lotSelected)?.associatedMilkerIndex,
    [lotSelected, lotsInDevice]
  );

  // Filter and group milker data
  const dataMilker = useMemo(
    () =>
      filterAndSortByMilker(milkerDeviceData)
        ?.map((data) => ({
          ...data,
          date: moment.utc(data.date).format('DD-MM-YYYY'),
        }))
        ?.reduce((acc, curr) => {
          if (!acc[curr.date]) {
            acc[curr.date] = [];
          }

          const milkerData = isDELPRO
            ? (curr as unknown as DeviceEventDelpro)?.data
                ?.filter((el) => el['Numero del grupo'] === `${associatedMilkerIndex}`)
                .map((el) => ({
                  index: Number(el['Numero del grupo']),
                  prodPromedio: Number(el['Promedio Produccion ayer']),
                  prodTotal: Number(el['Produccion total ayer']),
                }))
            : isAFIMILK
            ? (curr as unknown as DeviceEventAfimilk)?.data
                ?.filter((el) => el['Indice'] === `${associatedMilkerIndex}`)
                .map((el) => ({
                  index: Number(el['Indice']),
                  prodPromedio: Number(el['Leche Últimas 24H']),
                  prodTotal: Number(el['Producción Total']),
                }))
            : [];

          acc[curr?.date] = milkerData as any;
          return acc;
        }, {} as Record<string, { index: number; prodPromedio: number; prodTotal: number }[]>),
    [associatedMilkerIndex, isAFIMILK, isDELPRO, milkerDeviceData]
  );

  // Data for the selected lot
  const lotSelectedData = lotSelected ? groupedDeviceDownloadDataByLot[lotSelected] : [];

  // Group lotSelectedData data by date
  const groupedLotSelectedDataByDate = lotSelectedData?.reduce((acc, curr) => {
    const fechaShort = customBodyRenderDateOnly(curr.date);
    if (!acc[fechaShort]) {
      acc[fechaShort] = [];
    }
    acc[fechaShort].push(curr);
    return acc;
  }, {} as Record<string, DeviceEventDownload[]>);

  // Parse the data for each date: replace each data array in the groupedLotSelectedDataByDate with the parsed data
  const parsedDataRowsByDate = Object.entries(groupedLotSelectedDataByDate || {}).reduce((acc, [date, data]) => {
    acc[date] = parseFunction(data, {}, descargas, filterNotLoaded, recetas, ingredientes)[0];
    return acc;
  }, {} as Record<string, DeviceEventDownloadFormatted[]>);

  // Get the attributes to display for each date: replace each data array in the parsedDataRowsByDate with the summed values
  const parsedDataSummedByDate = Object.entries(parsedDataRowsByDate || {}).reduce((acc, [date, data]) => {
    const resultadoForDate = data.find((row) => (row as DeviceEventDownloadFormatted).isResultadoFinal);

    // Data del milker para el indice de lote y la fecha
    const milkerDataForDate = dataMilker[date] || [];
    const milkerLtTotal = redondearDosDecimales(
      milkerDataForDate?.reduce((acc, row) => acc + Number(row['prodTotal']), 0)
    );
    const milkerLtPromedio = redondearDosDecimales(
      milkerDataForDate?.reduce((acc, row) => acc + Number(row['prodPromedio']), 0)
    );
    const factorConversion = milkerLtTotal / (sumDoubleValues(resultadoForDate?.kgDescargadosMS) || 1);

    acc[date] = {
      date,
      rowType: 'value',
      lotKgMv: sumDoubleValues(resultadoForDate?.lotKgMv),
      toDownload: sumDoubleValues(resultadoForDate?.toDownload),
      downloaded: sumDoubleValues(resultadoForDate?.downloaded),
      kgTeoricosMSGuia: sumDoubleValues(resultadoForDate?.kgTeoricosMSGuia),
      kgDescargadosMS: sumDoubleValues(resultadoForDate?.kgDescargadosMS),
      promedioCantidadCab:
        redondearDosDecimales(
          data
            ?.filter((row) => !(row as DeviceEventDownloadFormatted).isResultadoFinal)
            ?.reduce((acc, row) => acc + (row as DeviceEventDownloadSummed).headsCount, 0) /
            (data.length - 1)
        ) || 0,
      count: data.filter((row) => !(row as DeviceEventDownloadFormatted).isResultadoFinal).length,
      milkerLtTotal,
      milkerLtPromedio,
      factorConversion,
    };
    return acc;
  }, {} as Record<string, Record<string, number | [number, number] | string>>);

  // Create the rows array for the table
  const tableRows = Object.values(parsedDataSummedByDate).sort((a, b) => (a.date > b.date ? 1 : -1));

  // Create the results array for the table, summing all the values
  const tableRowsResult = tableRows?.reduce(
    (acc, row) => {
      acc.lotKgMv = redondearDosDecimales(Number(acc.lotKgMv) + Number(row.lotKgMv));
      acc.toDownload = redondearDosDecimales(Number(acc.toDownload) + Number(row.toDownload));
      acc.downloaded = redondearDosDecimales(Number(acc.downloaded) + Number(row.downloaded));
      acc.kgTeoricosMSGuia = redondearDosDecimales(Number(acc.kgTeoricosMSGuia) + Number(row.kgTeoricosMSGuia));
      acc.kgDescargadosMS = redondearDosDecimales(Number(acc.kgDescargadosMS) + Number(row.kgDescargadosMS));
      acc.milkerLtTotal = redondearDosDecimales(Number(acc.milkerLtTotal) + Number(row.milkerLtTotal));
      acc.milkerLtPromedio = redondearDosDecimales(Number(acc.milkerLtPromedio) + Number(row.milkerLtPromedio));
      return acc;
    },
    {
      lotKgMv: 0,
      toDownload: 0,
      downloaded: 0,
      kgTeoricosMSGuia: 0,
      kgDescargadosMS: 0,
      milkerLtTotal: 0,
      milkerLtPromedio: 0,
    } as Record<string, number>
  );

  // Merge the two arrays in to one array of rows
  const rows = [
    ...tableRows,
    {
      ...tableRowsResult,
      rowType: 'resultado',
      isResultadoFinal: true,
    },
  ];

  return (
    <>
      {isFetching && <FullScreenLoader />}
      <ViewHeader title="Reporte de Lotes" dateSelector="range" />
      {!isFetching && lots?.length > 0 && (
        <div className="w-full sm:w-96 flex px-8">
          <Select
            name="lot"
            label="Lote"
            options={lots.map((lot) => ({
              name: lot,
              value: lot,
            }))}
            value={lotSelected}
            onChangeValue={(value: string) => {
              setLotSelected(value);
            }}
          />
        </div>
      )}
      {!isFetching && lots?.length === 0 && <p className="text-center text-xl mt-4">No hay lotes disponibles</p>}

      {!isFetching && lotSelected && (
        <div className="px-8 w-full flex flex-col mt-10">
          <div className="bg-neutral-900 border border-neutral-600 rounded-lg p-4 flex flex-col w-fit">
            <h1 className="text-2xl !mb-4">Lote {lotSelected}</h1>
            <TableSubsumView
              title="Reporte de Lotes"
              data={rows as any}
              columnsName={tableColumns}
              parseFunction={(data, _f, _e) => [data, {}]}
              tableProps={{
                withSelect: false,
              }}
            />

            <div className="w-full">
              <ReporteLotesGrafico
                data={tableRows?.map((row) => ({
                  date: String(row.date),
                  name: 'Lot Kg MV',
                  value:
                    Number(row.promedioCantidadCab) > 0 ? Number(row.lotKgMv) / Number(row.promedioCantidadCab) : 0,
                  lotKgMv: redondearDosDecimales(
                    Number(row.promedioCantidadCab) > 0 ? Number(row.lotKgMv) / Number(row.promedioCantidadCab) : 0
                  ),
                  toDownload: redondearDosDecimales(
                    Number(row.promedioCantidadCab) > 0 ? Number(row.toDownload) / Number(row.promedioCantidadCab) : 0
                  ),
                  downloaded: redondearDosDecimales(
                    Number(row.promedioCantidadCab) > 0 ? Number(row.downloaded) / Number(row.promedioCantidadCab) : 0
                  ),
                  kgTeoricosMSGuia: redondearDosDecimales(
                    Number(row.promedioCantidadCab) > 0
                      ? Number(row.kgTeoricosMSGuia) / Number(row.promedioCantidadCab)
                      : 0
                  ),
                  kgDescargadosMS: redondearDosDecimales(
                    Number(row.promedioCantidadCab) > 0
                      ? Number(row.kgDescargadosMS) / Number(row.promedioCantidadCab)
                      : 0
                  ),
                }))}
              />
            </div>

            {/*          <ReporteLotesConsumo
              data={[
                {
                  name: 'Consumo Teórico Cab Ms',
                  value: consumoTeoricoCabMs,
                },
                {
                  name: 'Consumo Real Cab Ms',
                  value: consumoRealCabMs,
                },
              ]}
            /> */}
          </div>
        </div>
      )}
    </>
  );
}

function groupDeviceDownloadDataByLot(data: DeviceEventDownload[]) {
  return data?.reduce((acc, row) => {
    const lot = row.lot;
    if (!acc[lot]) {
      acc[lot] = [];
    }
    acc[lot].push(row);
    return acc;
  }, {} as Record<string, DeviceEventDownload[]>);
}

function sumDoubleValues(value: [number, number] | number | undefined) {
  if (Array.isArray(value)) {
    return redondearDosDecimales(value[0] + value[1]);
  } else return value || 0;
}
