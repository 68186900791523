import { FocusableItem, FormItem } from 'design-system/input';
import styled from 'styled-components';

const SelectButton = styled.select`
  ${FocusableItem}
`;

type SelectOption = {
  value: string;
  name: string;
  disabled?: boolean;
};

export default function Select({
  name,
  label,
  placeholder,
  placeholderEnabled,
  options,
  value = '',
  onChangeValue = () => {},
  disabled,
  ...props
}: JSX.IntrinsicElements['select'] & {
  onChangeValue: (value: string) => void;
  options: SelectOption[];
  label?: string;
  placeholderEnabled?: boolean;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    props.onChange && props.onChange(event);
    onChangeValue(event.target.value);
  };

  return (
    <FormItem label={label}>
      <SelectButton
        name={name}
        id={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        // {...props}
      >
        {placeholder && (
          <option disabled={!placeholderEnabled} value="">
            {placeholder}
          </option>
        )}
        {options.map(({ value, name, disabled }, idx) => (
          <option
            value={value}
            key={idx}
            disabled={disabled}
            // Unset font color to NOT inherit from parent
            style={{ color: 'unset' }}
          >
            {name}
          </option>
        ))}
      </SelectButton>
    </FormItem>
  );
}
