import Input from 'components/input';
import { ChipInfo } from 'design-system/chip/chip-info';
import Select from 'design-system/select';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDescargas } from 'selectors/descargas.selectors';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { RECIPE_TYPE_HEADS, RECIPE_TYPE_KG, Recipe } from 'utils/device/event/diet';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';

const RecetaForm = ({ receta, editingEnabled }: { receta?: Recipe; editingEnabled?: boolean }) =>
  editingEnabled ? <RecetaFormEditable /> : receta ? <RecetaFormBase receta={receta} /> : null;
export default memo(RecetaForm);

function RecetaFormEditable() {
  const { watch } = useFormContext<Recipe>();
  const [id, mixingTime, name, realHeadCount, unloadGuide, type] = watch([
    'id',
    'mixingTime',
    'name',
    'realHeadCount',
    'unloadGuide',
    'type',
  ]);
  return <RecetaFormBase receta={{ id, mixingTime, name, realHeadCount, unloadGuide, type }} editingEnabled />;
}

function RecetaFormBase({ receta, editingEnabled }: { receta: Partial<Recipe>; editingEnabled?: boolean }) {
  const methods = useFormContext<Recipe>();
  const { setValue } = methods || {};

  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionFive = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected);

  const descargas = useSelector(selectDescargas);

  const UNLOAD_GUIDES_OPTIONS = useMemo(
    () =>
      descargas.map((descarga) => ({
        name: descarga.name,
        value: `${descarga.id}`,
      })),
    [descargas]
  );

  const showSelectID = !!receta?.id;

  return (
    <div className="flex flex-col gap-2">
      <TableRowTwoCols>
        <div className={!showSelectID ? 'w-full mobile:col-span-2' : 'w-full'}>
          <Input
            placeholder="Nombre"
            label="Nombre"
            defaultValue={receta?.name || ''}
            onBlur={(value: string) => {
              setValue?.('name', value || '');
            }}
            disabled={!editingEnabled}
            maxLength={isDeviceVersionFive ? 25 : 14}
          />
        </div>
        {showSelectID && (
          <div className="mb-1 w-full">
            <ChipInfo>ID de receta {receta?.id}</ChipInfo>
          </div>
        )}
      </TableRowTwoCols>
      <TableRowTwoCols>
        <Input
          placeholder="Tiempo de Mezcla"
          label="Tiempo de Mezcla"
          defaultValue={receta?.mixingTime}
          type="number"
          onBlur={(value: string) => {
            setValue?.('mixingTime', Number(value));
          }}
          disabled={!editingEnabled}
          min={0}
          max={99}
        />
        <Select
          name="Tipo Unidad"
          label="Tipo Unidad"
          placeholder="Tipo Unidad"
          options={[
            { name: 'Cabezas', value: RECIPE_TYPE_HEADS },
            { name: 'Kg', value: RECIPE_TYPE_KG },
          ]}
          onChangeValue={(value: string) => {
            setValue?.('type', value as typeof RECIPE_TYPE_HEADS | typeof RECIPE_TYPE_KG);
          }}
          value={receta?.type}
          disabled={!editingEnabled}
        />
      </TableRowTwoCols>
      <TableRowTwoCols>
        <Input
          placeholder="Cabezas reales/Kg"
          label="Cabezas reales/Kg"
          defaultValue={receta?.realHeadCount}
          type="number"
          onBlur={(value: string) => {
            setValue?.('realHeadCount', Number(value));
          }}
          disabled={!editingEnabled}
          min={0}
        />
        <Select
          name="Descarga asociada"
          label="Descarga asociada"
          placeholder="Sin descarga asociada"
          placeholderEnabled
          options={UNLOAD_GUIDES_OPTIONS}
          onChangeValue={(value: string) => {
            setValue?.(
              'unloadGuide',
              descargas.find((descarga) => descarga.id === Number(value))
            );
          }}
          value={`${receta?.unloadGuide?.id || ''}`}
          disabled={!editingEnabled}
        />
      </TableRowTwoCols>
    </div>
  );
}
