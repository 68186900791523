import { toast } from 'react-toastify';

export const mutateWithToast = async (
  promise: Promise<any>,
  onSuccess?: () => void,
  toastLabels: {
    pending: string;
    success: string;
    error: string;
  } = {
    pending: 'Aplicando cambios...',
    success: 'Cambios aplicados correctamente.',
    error: 'Error al aplicar cambios.',
  }
) => {
  try {
    await toast.promise(
      promise.then(async (res: any) => {
        if (res?.error) {
          throw new Error('Something went wrong.');
        }

        onSuccess?.();
      }),
      {
        pending: toastLabels.pending,
        success: toastLabels.success,
        error: toastLabels.error,
      }
    );
  } catch (error) {
    console.error(toastLabels.error, error);
  }
};
