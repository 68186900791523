import LiteTable from 'components/lite-table';
import { FullScreenLoader } from 'components/loader';
import SpaceDivider from 'components/space-divider';
import FormCard from 'design-system/form-card';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { Fragment } from 'react';
import { useGetDeviceDownloadDataQuery, useGetDeviceLoadDataQuery } from 'store/devices/devicesApi';
import { DeviceEventDownload, DeviceEventLoad } from 'utils/device/event/sip';
import { redondearDosDecimales } from 'utils/helpers/math';
import ConsumosPieChart from './ConsumosPieChart';

type IngredientsPercentages = Record<string, { percentage: number; kgDownloaded: number; kgLoaded: number }>;

type AugmentedDeviceEventDownload = DeviceEventDownload & {
  loads: DeviceEventLoad[];
  loadsTotal: number;
  ingredientsPercentages: IngredientsPercentages;
};

// For each entry in deviceDownloadData, add an additional field 'loads' with all the entries in deviceLoadData that have the same 'operationID' value.
function addLoadsToDownloadData(
  deviceDownloadData: DeviceEventDownload[],
  deviceLoadData: DeviceEventLoad[]
): AugmentedDeviceEventDownload[] {
  return deviceDownloadData.map((downloadData) => {
    const loadsByOperationID = deviceLoadData.filter((loadData) => loadData.operationID === downloadData.operationID);
    const loadsByOperationIDTotal = loadsByOperationID.reduce((acc, curr) => acc + curr.loaded, 0) || 1;

    // Create an object with a key for each ingredient in loads and its percentage of the total load.
    const ingredientsPercentages = loadsByOperationID.reduce((acc, curr) => {
      const percentage = redondearDosDecimales((curr.loaded / loadsByOperationIDTotal) * 100);
      acc[curr.ingredient] = {
        percentage,
        kgLoaded: curr.loaded,
        kgDownloaded: redondearDosDecimales(downloadData.downloaded * (percentage / 100)),
      };

      return acc;
    }, {} as IngredientsPercentages);

    return { ...downloadData, loads: loadsByOperationID, loadsTotal: loadsByOperationIDTotal, ingredientsPercentages };
  });
}

type AugmentedLot = {
  lot: string;
  ingredients: IngredientsPercentages;
  loadedTotal: number;
  downloadedTotal: number;
};

// For each entry in data, group the data by 'lot' and return an object with the 'lot' as key and sum the ingredientsPercentages for each lot.
function groupDataByLot(data: AugmentedDeviceEventDownload[]): Record<string, AugmentedLot> {
  return data.reduce((acc, curr) => {
    const { lot, ingredientsPercentages, loadsTotal, downloaded } = curr;

    if (!acc[lot]) {
      acc[lot] = {
        lot,
        ingredients: ingredientsPercentages,
        loadedTotal: loadsTotal,
        downloadedTotal: downloaded,
      };
    } else {
      acc[lot].loadedTotal += loadsTotal;
      acc[lot].downloadedTotal += downloaded;

      for (const ingredient in ingredientsPercentages) {
        if (acc[lot].ingredients[ingredient]) {
          acc[lot].ingredients[ingredient].kgLoaded += ingredientsPercentages[ingredient].kgLoaded;
          acc[lot].ingredients[ingredient].kgDownloaded += ingredientsPercentages[ingredient].kgDownloaded;
          acc[lot].ingredients[ingredient].kgDownloaded = redondearDosDecimales(
            acc[lot].ingredients[ingredient].kgDownloaded
          );
        } else {
          acc[lot].ingredients[ingredient] = ingredientsPercentages[ingredient];
        }
      }
    }

    return acc;
  }, {} as Record<string, AugmentedLot>);
}

function ConsumosPorLote() {
  const { columns } = useColumns('columnsTablaConsumosLote');

  const { data: deviceDownloadData = [], isFetching: isDeviceDownloadDataFetching } =
    useCalendarQuery(useGetDeviceDownloadDataQuery);

  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQuery(useGetDeviceLoadDataQuery);

  const deviceDownloadDataWithLoads = addLoadsToDownloadData(deviceDownloadData, deviceLoadData);

  const deviceDownloadDataGroupedByLot = groupDataByLot(deviceDownloadDataWithLoads);

  const isFetching = isDeviceDownloadDataFetching || isDeviceLoadDataFetching;

  return (
    <>
      {isFetching && <FullScreenLoader />}
      <ViewHeader title="Consumos por Lote" dateSelector="range" />
      <div className="content-view px-8">
        {Object.entries(deviceDownloadDataGroupedByLot).map(([lot, data], index) => {
          // Transform ingredients object into an array of objects with the ingredient name and its percentage.
          const tableData = Object.entries(data.ingredients).map(
            ([ingredient, { percentage, kgDownloaded, kgLoaded }]) => ({
              ingredient,
              percentage,
              kgDownloaded,
              kgLoaded,
            })
          );

          return (
            <Fragment key={String(lot) + index}>
              <FormCard
                title={lot}
                items={[
                  {
                    value: (
                      <div className="relative grid xl:grid-cols-2">
                        <div className="w-full h-full rounded-md overflow-hidden relative">
                          <LiteTable data={tableData} columns={columns} />
                        </div>
                        <div className="w-[70vw] xl:w-full h-[30vh] relative mx-auto my-auto p-4">
                          <ConsumosPieChart data={tableData} />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
              <SpaceDivider className="double" />
            </Fragment>
          );
        })}
      </div>
    </>
  );
}

export default ConsumosPorLote;
