import { customBodyRenderSummedAttribute, customBodyRenderSelectedColumn } from 'components/table/TableSubsum';

export const columnsReporteLotes = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'count',
    label: 'Comidas por Día',
    options: { filter: false, sort: false },
  },
  {
    name: 'promedioCantidadCab',
    label: 'Cantidad de Cabezas',
    options: { filter: false, sort: false },
  },
  {
    name: 'lotKgMv',
    label: 'Kg Lote MV Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'toDownload',
    label: 'Kg Descarga según Carga',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'downloaded',
    label: 'Kg Descargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'kgTeoricosMSGuia',
    label: 'Kg Teóricos MS Guía',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'kgDescargadosMS',
    label: 'Kg Descargados MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
];

export const columnsReporteLotesV2 = columnsReporteLotes.concat([
  {
    name: 'milkerLtTotal',
    label: 'Lt Ordeñados Lote',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'milkerLtPromedio',
    label: 'Lt Ordeñados Cab',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'factorConversion',
    label: 'Factor Conversión',
    options: {
      filter: false,
      sort: false,
    },
  },
]);
