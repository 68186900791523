import { IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Input from 'components/input';
import LiteTable from 'components/lite-table';
import TableSubsum from 'components/table/TableSubsum';
import { useColumns } from 'hooks/useColumns';
import cloneDeep from 'lodash/cloneDeep';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceSelectedStock } from 'store/devices/devicesSlice';
import {
  recetasActions,
  selectRecetaEditing
} from 'store/recetas/recetasSlice';
import {
  getMSNominalReceta, Recipe, RecipeIngredient
} from 'utils/device/event/diet';
import { customBodyRenderChangesArray } from 'utils/helpers/body-renders';
import MSNominal from '../ms-nominal';
import parseDataReceta, { RecipeIngredientFormatted } from './parser';

const TablaReceta = ({
  receta,
  editingEnabled,
}: {
  receta: Recipe;
  editingEnabled?: boolean;
}) =>
  !editingEnabled ? (
    <TablaRecetaNoEditable receta={receta} />
  ) : (
    <TablaRecetaEditable />
  );

export default memo(TablaReceta);

function TablaRecetaNoEditable({ receta }: { receta: Recipe }) {
  const sipnStockSelected = useSelector(selectDeviceSelectedStock);
  const { columns: columnsReceta } = useColumns('columnsTablaCreadorRecetas');
  const tableData = useMemo(
    () => parseDataReceta(receta, sipnStockSelected?.ingredients),
    [receta, sipnStockSelected?.ingredients]
  );

  return (
    <>
      <LiteTable data={tableData} columns={columnsReceta} />
      <MSNominal recetaParsed={tableData} />
    </>
  );
}

function TablaRecetaEditable() {
  const dispatch = useDispatch();
  const sipnStockSelected = useSelector(selectDeviceSelectedStock);

  const recetaEditing = useSelector(selectRecetaEditing);
  const { columns: columnsReceta } = useColumns('columnsTablaCreadorRecetas');

  const tableDataNewReceta = useMemo(
    () => parseDataReceta(recetaEditing, sipnStockSelected?.ingredients),
    [recetaEditing, sipnStockSelected?.ingredients]
  );

  const onKgMVEdit = (tableMeta: any, newValue: number) => {
    dispatch(
      recetasActions.editRecetaRow({ rowIndex: tableMeta.rowIndex, newValue })
    );
  };

  // columna Kg/Cab-MV
  columnsReceta[2].options['customBodyRender'] = (
    value: any,
    tableMeta: any,
    updateValue: any
  ) => {
    return tableMeta.rowIndex < tableDataNewReceta.length - 1 ? (
      <Input
        type="number"
        onBlur={(value) => {
          onKgMVEdit(tableMeta, Number(value));
          updateValue(value);
        }}
        onChange={(value) => updateValue(value)}
        value={value}
        disabled={tableDataNewReceta[tableMeta.rowIndex].deleted}
      />
    ) : (
      <p>{value}</p>
    );
  };

  // columna Kg/Cab-MS
  columnsReceta[3].options['customBodyRender'] = (
    value: any,
    tableMeta: any,
    updateValue: any
  ) => {
    return tableMeta.rowIndex < tableDataNewReceta.length - 1 ? (
      <Input
        type="number"
        onBlur={(value) => {
          const { dryMatterPercentage = 1 } =
            tableMeta.tableData[tableMeta.rowIndex];
          onKgMVEdit(tableMeta, Number(value) / dryMatterPercentage);
          updateValue(value);
        }}
        onChange={(value) => updateValue(value)}
        value={value}
        disabled={tableDataNewReceta[tableMeta.rowIndex].deleted}
      />
    ) : (
      <p>{value}</p>
    );
  };

  columnsReceta[0].options['customBodyRender'] = (
    // Columna orden
    _value: any,
    tableMeta: any
  ) => {
    if (tableMeta.rowIndex < tableDataNewReceta.length - 1) {
      return (
        <div>
          {tableMeta.rowIndex > 0 && (
            <IconButton
              aria-label="ordenar-arriba"
              size="small"
              onClick={() =>
                dispatch(
                  recetasActions.onMoveRowUp({ rowIndex: tableMeta.rowIndex })
                )
              }
            >
              <ArrowUpwardIcon fontSize="inherit" />
            </IconButton>
          )}
          {tableMeta.rowIndex < tableDataNewReceta.length - 2 && (
            <IconButton
              aria-label="ordenar-abajo"
              size="small"
              onClick={() =>
                dispatch(
                  recetasActions.onMoveRowDown({ rowIndex: tableMeta.rowIndex })
                )
              }
            >
              <ArrowDownwardIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <TableSubsum
        title={'Receta'}
        data={tableDataNewReceta}
        columnas={columnsReceta}
        onToggleRow={() => {}}
        onColumnsViewChange={() => {}}
        withSelect={false}
        tableOptions={{
          pagination: false,
          filter: false,
        }}
      />
      <MSNominal recetaParsed={tableDataNewReceta} />
    </>
  );
}

export function TablaRecetaDiferencias({
  recetaVieja,
  recetaNueva,
}: {
  recetaVieja: Recipe;
  recetaNueva: Recipe;
}) {
  const { columns: columnsReceta } = useColumns('columnsTablaCreadorRecetas');
  const sipnStockSelected = useSelector(selectDeviceSelectedStock);
  const tableDataVieja = useMemo(
    () => parseDataReceta(recetaVieja, sipnStockSelected?.ingredients),
    [recetaVieja, sipnStockSelected?.ingredients]
  );
  const tableDataNueva = useMemo(
    () => parseDataReceta(recetaNueva, sipnStockSelected?.ingredients),
    [recetaNueva, sipnStockSelected?.ingredients]
  );

  const tablaViejaEliminados: RecipeIngredientFormatted[] = useMemo(
    () =>
      tableDataVieja
        .filter(
          (row: RecipeIngredientFormatted) =>
            !tableDataNueva.find(
              (rowVieja: RecipeIngredient) => rowVieja.name === row.name
            )
        )
        .map((row: RecipeIngredientFormatted) => ({ ...row, deleted: true })),
    [tableDataNueva, tableDataVieja]
  );
  const tablaNuevaWithChanges = useMemo(
    () =>
      tablaViejaEliminados.concat(
        tableDataNueva.map((row: RecipeIngredientFormatted) => {
          const rowVieja = tableDataVieja.find(
            (rowVieja: RecipeIngredientFormatted) => rowVieja.name === row.name
          );
          if (!rowVieja) return { ...row, nuevo: true };

          const changes = (Object.keys(row) as Array<keyof typeof row>).reduce(
            (acc, current) => {
              if (
                !(Array.isArray(row[current]) && row[current].length === 0) &&
                row[current] !== rowVieja[current]
              )
                return { ...acc, [current]: [rowVieja[current], row[current]] };
              return acc;
            },
            {} as RecipeIngredientFormatted
          );
          return {
            ...row,
            ...changes,
          };
        })
      ),
    [tablaViejaEliminados, tableDataNueva, tableDataVieja]
  );

  const columnasWithChangesRenderer = useMemo(() => {
    let cols = cloneDeep(columnsReceta);
    cols.forEach((col: any, cix: number) =>
      cix
        ? (col.options.customBodyRender = (value: any) =>
            customBodyRenderChangesArray(
              value,
              cix === cols.length - 1 || cix === cols.length - 2
            ))
        : null
    );
    return cols;
  }, [columnsReceta]);

  const msNominalNueva = useMemo(
    () => getMSNominalReceta(tableDataNueva),
    [tableDataNueva]
  );
  const msNominalVieja = useMemo(
    () => getMSNominalReceta(tableDataVieja),
    [tableDataVieja]
  );

  return (
    <>
      <LiteTable
        data={tablaNuevaWithChanges}
        columns={columnasWithChangesRenderer}
      />
      <p>
        <span>%MS Nominal: </span>
        {msNominalNueva === msNominalVieja ? (
          <span>{`${msNominalNueva}%`}</span>
        ) : (
          <>
            <span
              style={{
                backgroundColor: 'rgba(255, 0, 0,0.2)',
                color: 'red',
              }}
            >{`${msNominalVieja}%`}</span>{' '}
            <span
              style={{
                backgroundColor: 'rgba(139, 195, 74,0.2)',
                color: 'green',
              }}
            >{`${msNominalNueva}%`}</span>
          </>
        )}
      </p>
    </>
  );
}
