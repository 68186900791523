import es from 'date-fns/locale/es'; // the locale you want
import { DatePickerComponent } from 'design-system/date-picker';
import { FormItem } from 'design-system/input';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { now, today } from 'utils/helpers';
registerLocale('es', es); // register it with the name you want

export function SelectFecha({ onSelect, fechaSelected }: { onSelect: any; fechaSelected: string }) {
  return (
    <FormItem label="Fecha">
      <DatePickerComponent
        selected={new Date(fechaSelected)}
        onChange={(fecha: Date) => onSelect(now(fecha).toISOString())}
        locale="es"
        dateFormat="dd/MM/yyyy"
      />
    </FormItem>
  );
}

export function SelectBetweenFechas({
  onSelectFechaInicio,
  onSelectFechaFin,
  fechaInicio,
  fechaFin,
  maxDateRangeInDays,
}: SelectBetweenFechasProps) {
  return (
    <>
      <FormItem label="Desde">
        <DatePickerComponent
          selected={new Date(fechaInicio)}
          onChange={(fecha: Date) => onSelectFechaInicio(now(fecha).toISOString())}
          locale="es"
          dateFormat="dd/MM/yyyy"
          minDate={
            maxDateRangeInDays
              ? new Date(
                  moment
                    .utc(fechaFin)
                    .subtract(maxDateRangeInDays, 'day')
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    .toISOString()
                )
              : undefined
          }
          maxDate={new Date(fechaFin)}
        />
      </FormItem>
      <FormItem label="Hasta">
        <DatePickerComponent
          selected={new Date(fechaFin)}
          onChange={(fecha: Date) => onSelectFechaFin(now(fecha).endOf('day').toISOString())}
          locale="es"
          dateFormat="dd/MM/yyyy"
          maxDate={new Date(today)}
        />
      </FormItem>
    </>
  );
}

export function SelectBetweenFechasOnePicker({
  onSelectFechaInicio,
  onSelectFechaFin,
  fechaInicio,
  fechaFin,
  maxDateRangeInDays,
  disabled,
}: SelectBetweenFechasProps) {
  const [startDate, setStartDate] = useState<Date>(new Date(fechaInicio));
  const [endDate, setEndDate] = useState<Date>(new Date(fechaFin));

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    console.log('start', start);
    setStartDate(start);
    setEndDate(end);
    if (end) {
      onSelectFechaInicio(now(start).startOf('day').toISOString());
      onSelectFechaFin(now(end).endOf('day').toISOString());
    }
  };

  useEffect(() => {
    setStartDate(new Date(fechaInicio));
    setEndDate(new Date(fechaFin));
  }, [fechaInicio, fechaFin]);

  return (
    <FormItem label="Período">
      <DatePickerComponent
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        locale="es"
        dateFormat="dd/MM/yyyy"
        maxDate={
          !endDate && maxDateRangeInDays
            ? new Date(
                moment.min([moment(), moment(startDate).add(maxDateRangeInDays, 'day').startOf('day')]).toISOString()
              )
            : new Date()
        }
        disabled={disabled}
      />
    </FormItem>
  );
}
interface SelectBetweenFechasProps {
  onSelectFechaInicio: any;
  onSelectFechaFin: any;
  fechaInicio: string;
  fechaFin: string;
  maxDateRangeInDays?: number;
  disabled?: boolean;
}
