import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ChipToggle from 'design-system/chip-toggle';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useToggle } from 'hooks/useToggle';
import { useCallback } from 'react';
import { useGetDeviceLoadDataQuery, useToggleSumMutation } from 'store/devices/devicesApi';
import { VERSION, isDeviceVersionAtLeast, isTrue } from 'utils/helpers/validation';
import parseFunction from './parser';
import { useSelector } from 'react-redux';
import { selectDeviceSelected } from 'store/devices/devicesSlice';

function Pesadas() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQuery(useGetDeviceLoadDataQuery);
  const [toggleSum, { isLoading: isTogglingSum }] = useToggleSumMutation();
  const [removePorcentajeKgZeros, toggleRemovePorcentajeKgZeros] = useToggle(false);

  const columnsName = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected) ? 'columnsPesadasV5' : 'columnsPesadas';

  const customToolbar = useCallback(
    () => (
      <div className="inline-block">
        <ChipToggle
          label={`Filtrar no cargados`}
          onToggle={toggleRemovePorcentajeKgZeros}
          toggleValue={isTrue(removePorcentajeKgZeros)}
          noBorder
        />
      </div>
    ),
    [removePorcentajeKgZeros, toggleRemovePorcentajeKgZeros]
  );

  const isLoading = isDeviceLoadDataFetching || isTogglingSum;

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ViewHeader title="Pesadas" dateSelector="range" />
      <TableSubsumView
        title="Pesadas"
        data={deviceLoadData}
        columnsName={columnsName}
        parseFunction={parseFunction}
        extraParseFunctionArgs={[removePorcentajeKgZeros]}
        onToggleRow={toggleSum}
        tableProps={{
          tableOptions: {
            customToolbar,
          },
        }}
      />
    </>
  );
}

export default Pesadas;
