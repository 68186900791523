import AlertDialog from 'components/alert-dialog';
import Input from 'components/input';
import ChipToggle from 'design-system/chip-toggle';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import { ChipStock } from 'design-system/chip/chip-stock';
import { ChipSuccess } from 'design-system/chip/chip-success';
import FormCard from 'design-system/form-card';
import { IconDollarSign, IconPercentage } from 'design-system/icon';
import Select from 'design-system/select';
import SidebarSlide from 'design-system/sidebar-slide';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { useModal } from 'hooks/useModal';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useDeleteStockIngredientMutation,
  useGetDeviceTasksQuery,
  useUpdateStockIngredientMutation,
} from 'store/devices/devicesApi';
import { selectDeviceSelected, selectDeviceSelectedStock } from 'store/devices/devicesSlice';
import { Ingredient } from 'utils/device/stock';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';
import { VERSION, isDeviceVersionAtLeast, isTrue } from 'utils/helpers/validation';

const SELECT_OPTIONS_MODOS = ['manual', 'Auto'].map((modo) => ({
  name: modo,
  value: modo,
}));

function ModalEditarIngrediente({ isOpen, onClose = () => {} }: { isOpen: boolean; onClose: () => void }) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionFive = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected);

  const sipnStockSelected = useSelector(selectDeviceSelectedStock);
  const [updateStockIngredient] = useUpdateStockIngredientMutation();
  const [deleteStockIngredient] = useDeleteStockIngredientMutation();
  const getDeviceTasks = useGetDeviceTasksQuery({ serialNumber: deviceSelected?.serialNumber || '' });

  const [ingredienteEditing, setIngredienteEditing] = useState<Ingredient>({} as Ingredient);
  const [ingredientIndexInArray, setIngredientIndexInArray] = useState(0);

  const { id: stockId, ingredients = [] } = sipnStockSelected || {};

  const {
    id: ingredientId,
    dryMatterPercentage,
    name,
    price,
    stock,
    mode,
    automaticCorrection,
    numberAutomatic,
    isAutomated,
    riskIng,
  } = ingredienteEditing || {};

  const [newName, setNewName] = useState('');
  const [newIngreso, setNewIngreso] = useState(0);
  const [newIngresoDetalle, setNewIngresoDetalle] = useState('');
  const [isOpenEliminarIngrediente, onOpenEliminarIngrediente, onCloseEliminarIngrediente] = useModal();

  const SELECT_OPTIONS_INGREDIENTE = useMemo(
    () =>
      ingredients.map(({ name }) => ({
        name,
        value: name,
      })),
    [ingredients]
  );

  const onSave = async () => {
    if (stockId) {
      const promise = updateStockIngredient({
        stockId,
        ingredient: {
          ...ingredienteEditing,
          name: newName,
          stock: stock + newIngreso,
        },
        detail: newIngreso ? newIngresoDetalle : undefined,
      });

      const onSuccess = () => {
        getDeviceTasks.refetch();
        handleClose();
      };

      await mutateWithToast(promise, onSuccess, {
        pending: 'Guardando ingrediente...',
        success: 'Ingrediente guardado correctamente.',
        error: 'Error al guardar el ingrediente.',
      });
    }
  };

  const onEliminarIngrediente = () => {
    let newStock = cloneDeep(sipnStockSelected);
    if (newStock) {
      newStock.ingredients = newStock.ingredients?.filter(
        (_ingredient, ingIndex) => ingredientIndexInArray !== ingIndex
      );
      deleteStockIngredient({ stock: newStock });
      onCloseEliminarIngrediente();
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (ingredients.length > 0) {
      setIngredienteEditing(ingredients[0]);
      setIngredientIndexInArray(0);
      setNewName(ingredients[0].name);
      setNewIngreso(0);
      setNewIngresoDetalle('');
    }
  }, [isOpen, ingredients]);

  const onSelectIngrediente = (value: string) => {
    const ingredienteSelectedIndex = ingredients.findIndex((ingrediente) => ingrediente.name === value);
    if (ingredienteSelectedIndex > -1) {
      setIngredienteEditing(ingredients[ingredienteSelectedIndex]);
      setNewName(ingredients[ingredienteSelectedIndex].name);
      setIngredientIndexInArray(ingredienteSelectedIndex);
    }
  };

  const onNuevoPrecio = (value: string) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      price: Number(value),
    });
  };
  const onNuevoPorcentajeMS = (value: string) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      dryMatterPercentage: Number(value) / 100,
    });
  };

  const onNewAutomaticCorrection = (value: string) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      automaticCorrection: Number(value),
    });
  };

  const onNewNumberAutomatic = (value: string) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      numberAutomatic: Number(value),
    });
  };

  const onNewIsAutomated = (value: boolean) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      isAutomated: value,
    });
  };

  const onNewRiskIng = (value: boolean) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      riskIng: value,
    });
  };

  const onNewMode = (value: string) => {
    setIngredienteEditing({
      ...ingredienteEditing,
      mode: value as 'manual' | 'Auto',
    });
  };

  const onNuevoNombre = (value: string) => setNewName(value);
  const onNewIngreso = (value: string) => setNewIngreso(Number(value));
  const onNewIngresoDetalle = (value: string) => setNewIngresoDetalle(value);

  return (
    <>
      <SidebarSlide
        isOpen={isOpen}
        onClose={handleClose}
        title={'Editar ingrediente'}
        actions={[
          {
            autoFocus: false,
            onClick: onOpenEliminarIngrediente,
            text: 'Eliminar',
            variant: 'text-danger',
            disabled: !!ingredientId && ingredientId !== null,
            tooltip: ingredientId ? 'No pueden eliminarse ingredientes que estén en balanza.' : '',
          },
          {
            autoFocus: false,
            onClick: onSave,
            text: 'Guardar',
            disabled: !name || !ingredientId,
            variant: 'fill',
          },
        ]}
      >
        <FormCard
          items={[
            {
              value: (
                <TableRowTwoCols className="mobile:flex">
                  <Select
                    name="Ingrediente"
                    label="Ingrediente"
                    placeholder="Ingrediente"
                    options={SELECT_OPTIONS_INGREDIENTE}
                    onChangeValue={onSelectIngrediente}
                    value={name}
                  />
                  <div className="mb-1">
                    <ChipStock>{`Existencia actual: ${stock}`}</ChipStock>
                  </div>
                </TableRowTwoCols>
              ),
            },
            {
              value: !ingredientId ? (
                <ChipQuestionMark>El ingrediente no está guardado en la balanza.</ChipQuestionMark>
              ) : (
                <TableRowTwoCols>
                  <Input
                    placeholder="Nombre"
                    label="Nombre"
                    value={newName || ''}
                    onChange={onNuevoNombre}
                    maxLength={isDeviceVersionFive ? 25 : 14}
                  />
                  <div className="mb-1">
                    <ChipSuccess>
                      Ingrediente guardado en balanza con ID {ingredientId ? ingredientId : '-'}.
                    </ChipSuccess>
                  </div>
                </TableRowTwoCols>
              ),
            },
            {
              value: (
                <TableRowTwoCols>
                  <Input
                    placeholder="Ingreso/ajuste existencia"
                    label="Ingreso/ajuste existencia"
                    value={newIngreso || ''}
                    type="number"
                    onChange={onNewIngreso}
                  />
                  <Input
                    placeholder="Detalle"
                    label="Detalle"
                    value={newIngresoDetalle || ''}
                    onChange={onNewIngresoDetalle}
                    maxLength={50}
                    disabled={!newIngreso}
                  />
                </TableRowTwoCols>
              ),
            },
            {
              value: (
                <TableRowTwoCols>
                  <Input
                    placeholder="Precio"
                    label="Precio"
                    value={price || ''}
                    type="number"
                    onChange={onNuevoPrecio}
                    startAdornment={<IconDollarSign />}
                  />
                  <Input
                    placeholder="%MS"
                    label="%MS"
                    value={dryMatterPercentage ? dryMatterPercentage * 100 : ''}
                    type="number"
                    onChange={onNuevoPorcentajeMS}
                    startAdornment={<IconPercentage />}
                    max={100}
                    min={0}
                    step={'.1'}
                  />
                </TableRowTwoCols>
              ),
            },
            {
              value: (
                <TableRowTwoCols>
                  <Input
                    placeholder="Correción"
                    label="Correción"
                    value={automaticCorrection || ''}
                    type="number"
                    onChange={onNewAutomaticCorrection}
                  />
                  <Input
                    placeholder="Nº A"
                    label="Nº A"
                    value={numberAutomatic || ''}
                    type="number"
                    onChange={onNewNumberAutomatic}
                  />
                </TableRowTwoCols>
              ),
            },

            {
              value: (
                <TableRowTwoCols>
                  <Select
                    name="Modo"
                    label="Modo"
                    placeholder="Modo"
                    options={SELECT_OPTIONS_MODOS}
                    onChangeValue={onNewMode}
                    value={mode}
                  />
                  <div className="grid grid-cols-2 w-full gap-4">
                    <ChipToggle label={`Automatizado`} onToggle={onNewIsAutomated} toggleValue={isTrue(isAutomated)} />
                    <ChipToggle label={`Risk`} onToggle={onNewRiskIng} toggleValue={isTrue(riskIng)} />
                  </div>
                </TableRowTwoCols>
              ),
            },
          ]}
        />
      </SidebarSlide>
      <AlertDialog
        isOpen={isOpenEliminarIngrediente}
        onClose={onCloseEliminarIngrediente}
        title="Eliminar Ingrediente"
        onPositiveClick={onEliminarIngrediente}
        positiveLabel="Eliminar"
      >
        ¿Está seguro que desea eliminar el ingrediente? Esta acción no se puede deshacer.
      </AlertDialog>
    </>
  );
}

export default ModalEditarIngrediente;
