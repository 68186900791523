import LiteTable from 'components/lite-table';
import SidebarSlide from 'design-system/sidebar-slide';
import { useColumns } from 'hooks/useColumns';
import { customBodyRenderChangesArray } from 'utils/helpers/body-renders';
import TablaReceta, {
  TablaRecetaDiferencias,
} from 'screens/creador-recetas/tabla-receta';
import { DeviceEvent } from 'utils/device/event/sip/event';
import { Lot } from 'utils/device/lot';
import parseGuiaDescarga from 'screens/creador-descargas/tabla-descarga/parser';
import TablaDescarga from 'screens/creador-descargas/tabla-descarga';

// Bug unloadGuideUpdateFromClient - deberia mostrar diferencias en kgLot
function EventHistoryDetail({
  isOpen,
  onClose = () => {},
  evento,
}: {
  isOpen: boolean;
  onClose: () => any;
  evento: DeviceEvent;
}) {
  const isEventoFromV2 = !!evento?.data?.newValue?.kgHeads;
  const { columns: columnsLotes } = useColumns(
    isEventoFromV2 ? 'columnsLotesV2' : 'columnsLotes'
  );
  const { columns: columnsCreadorDescarga } = useColumns(
    'columnsTablaCreadorDescargas'
  );
  const { data: { action = '' } = {} } = evento || {};
  const parsedProperty = action.toLowerCase().includes('recipe')
    ? 'receta'
    : action.toLowerCase().includes('lot')
    ? 'lote'
    : action.toLowerCase().includes('unloadguide')
    ? 'guía de descarga'
    : '';
  const parsedAction = action.toLowerCase().includes('update')
    ? 'update'
    : action.toLowerCase().includes('delete')
    ? 'delete'
    : action.toLowerCase().includes('add') ||
      action.toLowerCase().includes('create')
    ? 'add'
    : '';

  const columnsLoteRenderDif = columnsLotes.map((col: any) => ({
    ...col,
    options: {
      ...col.options,
      customBodyRender:
        col.options?.customBodyRender || customBodyRenderChangesArray,
    },
  }));
  const columnsCreadorDescargaRenderDif = columnsCreadorDescarga.map(
    (col: any) => ({
      ...col,
      options: {
        ...col.options,
        customBodyRender:
          col.options?.customBodyRender || customBodyRenderChangesArray,
      },
    })
  );

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={onClose}
      title={
        parsedAction === 'add'
          ? `Nueva ${parsedProperty} ${evento?.data?.newValue?.name}`
          : `Cambios ${parsedProperty} ${evento?.data?.prevValue?.name}`
      }
    >
      <div className="px-8 py-8">
        {parsedProperty === 'receta' ? (
          parsedAction === 'add' ? (
            <TablaReceta receta={evento?.data?.newValue} />
          ) : (
            <TablaRecetaDiferencias
              recetaVieja={evento?.data?.prevValue}
              recetaNueva={evento?.data?.newValue}
            />
          )
        ) : parsedProperty === 'lote' ? (
          <LiteTable
            title="Lote"
            data={[
              parseToDiferencias(
                evento?.data?.prevValue,
                evento?.data?.newValue
              ),
            ]}
            columns={columnsLoteRenderDif}
          />
        ) : parsedProperty === 'guía de descarga' ? (
          parsedAction === 'add' ? (
            <TablaDescarga descarga={evento?.data?.newValue} lite />
          ) : (
            <LiteTable
              data={parseGuiaDescarga({
                ...evento?.data?.newValue,
                lots: parseToDiferenciasUnloadGuide(
                  evento?.data?.prevValue?.lots,
                  evento?.data?.newValue?.lots
                ),
              })}
              columns={columnsCreadorDescargaRenderDif}
            />
          )
        ) : null}
      </div>
    </SidebarSlide>
  );
}

export default EventHistoryDetail;

function parseToDiferencias(prevValue: any, newValue: any) {
  return Object.keys(prevValue).reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]:
        prevValue[currKey] === newValue[currKey]
          ? newValue[currKey]
          : [prevValue[currKey], newValue[currKey]],
    }),
    {}
  );
}

function parseToDiferenciasUnloadGuide(
  prevLots: Lot[] = [],
  newLots: Lot[] = []
) {
  const prevLotsDeleted = prevLots
    .filter(
      (prevLot) => !newLots.find((newLot) => prevLot.name === newLot.name)
    )
    .map((prevLot) => ({ ...prevLot, deleted: true }));
  const newLotsFlagged = newLots.map((newLot) => {
    const prevLotValues = prevLots.find(
      (prevLot) => prevLot.name === newLot.name
    );
    if (!prevLotValues) return { ...newLot, nuevo: true };
    else
      return Object.keys(newLot).reduce(
        (acc, currKey) => ({
          ...acc,
          [currKey]:
            prevLotValues[currKey as keyof Lot] === newLot[currKey as keyof Lot]
              ? newLot[currKey as keyof Lot]
              : [
                  prevLotValues[currKey as keyof Lot],
                  newLot[currKey as keyof Lot],
                ],
        }),
        {}
      );
  });
  return newLotsFlagged.concat(prevLotsDeleted);
}
