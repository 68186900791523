import { customBodyRenderDate, customBodyRenderDateToLocal } from 'utils/helpers/body-renders';

export const columnsMensajesControl = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateToLocal,
    },
  },
  {
    name: 'creationTime',
    label: 'Hora guardada',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'temp',
    label: 'Temperatura',
    options: { filter: false, sort: false },
  },
  {
    name: 'vTractor',
    label: 'vTractor',
    options: { filter: false, sort: false },
  },
  { name: 'iUC', label: 'iUC', options: { filter: false, sort: false } },
  { name: 'signal', label: 'Signal', options: { filter: false, sort: false } },
  {
    name: 'pAS',
    label: '1°Arch.Enviar UC',
    options: { filter: false, sort: false },
  },
  {
    name: 'idLA',
    label: 'IDActual UC',
    options: { filter: false, sort: false },
  },
  {
    name: 'nAS',
    label: 'Cantidad Datos UC',
    options: { filter: false, sort: false },
  },
  {
    name: 'pASMC',
    label: '1°Arch.Enviar MC',
    options: { filter: false, sort: false },
  },
  {
    name: 'idLAMC',
    label: 'IDActual MC',
    options: { filter: false, sort: false },
  },
  {
    name: 'nASMC',
    label: 'Cantidad Datos MC',
    options: { filter: false, sort: false },
  },
];

export const columnsMensajesControlV5 = columnsMensajesControl.concat([
  {
    name: 'idLAGPS',
    label: 'idLAGPS',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'idLAGPSMC',
    label: 'idLAGPSMC',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'nASGPS',
    label: 'nASGPS',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'nASGPSMC',
    label: 'nASGPSMC',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'pASGPS',
    label: 'pASGPS',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'pASGPSMC',
    label: 'pASGPSMC',
    options: {
      filter: false,
      sort: false,
    },
  },
]);
