import { useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  DEFAULT_FONT_COLOR,
  DEFAULT_BACKGROUND,
  BORDER_COLOR,
  HOVER_COLOR,
  OPACITY_LEVEL,
  HAASTEN_LIGHT,
} from '../colors';
import { saveAs } from 'file-saver';
import { write, utils } from 'xlsx';
import { capitalize, isNumber } from 'lodash';
import { customBodyRenderDate } from 'utils/helpers/body-renders';

const EXCLUDE_COLUMNS = ['isSelected'];

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTable: {
        root: {
          borderCollapse: 'initial',
        },
      },
      MuiPaper: {
        root: {
          overflow: 'auto',
          color: DEFAULT_FONT_COLOR,
          backgroundColor: 'transparent',
        },
      },
      MuiIconButton: {
        root: {
          color: DEFAULT_FONT_COLOR,
          backgroundColor: OPACITY_LEVEL,
          borderRadius: 0,
          margin: 0,
          // marginLeft: ,
        },
      },
      MUIDataTableToolbar: {
        root: {
          padding: 0,
        },
        titleText: {
          margin: '0 !important',
        },
        iconActive: {
          color: DEFAULT_FONT_COLOR,
          backgroundColor: HOVER_COLOR,
          '&:hover': {
            opacity: 0.8,
          },
        },
        icon: {
          '&:hover': {
            opacity: 0.8,
          },
        },
        actions: {
          padding: '8px',
        },
      },
      MUIDataTableSearch: {
        searchText: {
          backgroundColor: 'transparent',
        },
        searchIcon: {
          color: DEFAULT_FONT_COLOR,
        },
      },
      MuiInputBase: {
        root: {
          color: DEFAULT_FONT_COLOR,
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: `1px solid ${DEFAULT_FONT_COLOR}`,
          },
          '&:after': {
            borderBottom: `2px solid ${DEFAULT_FONT_COLOR}`,
          },
          'input:focus': {
            border: 'none',
          },
          '&& input': {
            border: 'none',
          },
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:hover': {
            opacity: 0.8,
          },
          '&:not(:last-child)': {
            '&& td': {
              borderBottom: `1px solid ${BORDER_COLOR}`,
            },
          },
        },
      },
      MuiTableCell: {
        head: {
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
        root: {
          borderBottom: `none`,
          backgroundColor: 'transparent',
        },
        body: {
          color: DEFAULT_FONT_COLOR,
          whiteSpace: 'nowrap',
        },
      },
      MUIDataTableHeadRow: {
        root: {
          position: 'sticky',
          top: 0,
          backgroundColor: OPACITY_LEVEL,
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: 'transparent',
          color: DEFAULT_FONT_COLOR,
        },
        data: {
          backgroundColor: 'transparent',
          color: DEFAULT_FONT_COLOR,
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent',
        },
      },
      MuiFormControl: {
        root: {
          backgroundColor: DEFAULT_BACKGROUND,
        },
      },
      MuiCheckbox: {
        root: {
          color: HAASTEN_LIGHT,
        },
      },
      MUIDataTableFilter: {
        root: {
          backgroundColor: DEFAULT_BACKGROUND,
        },
        checkboxFormControlLabel: {
          color: HAASTEN_LIGHT,
        },
        checkboxListTitle: {
          color: HAASTEN_LIGHT,
        },
        title: {
          color: DEFAULT_FONT_COLOR,
        },
      },
      MUIDataTableViewCol: {
        title: {
          color: DEFAULT_FONT_COLOR,
        },
        label: {
          color: DEFAULT_FONT_COLOR,
        },
      },
    },
  });

export default function Table({
  title = '',
  data,
  columns,
  options = {},
  rowsSelected = [],
  onColumnViewChange,
}: TableProps) {
  let tableOptions = useMemo(() => {
    let opt = {
      ...defaultTableOptions(),
      ...options,
      rowsSelected,
      onColumnViewChange: (changedColumn: any, action: string) => {
        const newColumnasVisibles = columns.map((columna) => {
          const newCol = { ...columna, options: { ...columna.options } };
          if (columna.name === changedColumn) newCol.options.display = action === 'add';
          return newCol;
        });
        if (onColumnViewChange) onColumnViewChange(newColumnasVisibles);
      },
      onDownload: (_buildHead: any, _buildBody: any, columns: any, values: any) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const json = values.reduce((result: any, val: any) => {
          const temp = {} as any;
          val.data.forEach((v: any, idx: any) => {
            const columnName = columns[idx].name;
            if (EXCLUDE_COLUMNS.includes(columnName)) return;

            const display = columns[idx].display === 'true';
            if (!display) return;

            const label = columns[idx].label;

            let bodyRender = columns[idx].customBodyRender;

            if (columnName === 'date') {
              bodyRender = isNumber(v) ? customBodyRenderDate : undefined;
            }

            if (columnName === 'state') bodyRender = (value: string) => capitalize(value);

            if (Array.isArray(v))
              bodyRender = (v: string[]) =>
                v.every((val) => isNumber(val))
                  ? v
                      .map((val) => Number(val))
                      .filter((val) => isNumber(val))
                      .reduce((acc, val) => acc + val, 0)
                  : '';

            temp[label] = v !== undefined ? (bodyRender ? bodyRender(v) : v) : '';
          });
          result.push(temp);
          return result;
        }, []);

        const fileName = `${title} - Haasten`;
        const ws = utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        // cancel default  CSV download from table
        return false;
      },
    };
    return opt;
  }, [columns, onColumnViewChange, options, rowsSelected, title]);

  // actualizando la key se puede rerenderizar la tabla, cambiando las options no se actualiza
  const key = `datatable-${title}-${Array.isArray(rowsSelected) ? rowsSelected.length : 1}`;

  return (
    <div className="table-container">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable key={key} title={''} data={data} columns={columns} options={tableOptions} />
      </MuiThemeProvider>
    </div>
  );
}

export interface TableProps {
  title?: string;
  data: any[];
  columns: any[];
  rowsSelected?: any[];
  options?: any;
  onColumnViewChange?: (columnas: any) => void;
}

function defaultTableOptions() {
  return {
    filterType: 'checkbox',
    selectableRows: 'none',
    rowsPerPage: 20,
    responsive: 'standard',
    fixedHeaderOptions: { xAxis: false, yAxis: false },
    textLabels: {
      body: {
        noMatch: 'No se encontraron datos.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar Excel',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todo',
        title: 'FILTROS',
        reset: 'RESETEAR',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/ocultar columnas',
      },
      selectedRows: {
        text: 'fila(s) seleccionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
  };
}
