import Button from 'components/button';
import { SelectBetweenFechasOnePicker, SelectFecha } from 'components/fecha';
import { ProtectedView } from 'components/protected-view';
import Select from 'design-system/select';
import { TextHeader } from 'design-system/text';
import { useRouting } from 'hooks/useRouting';
import { useDispatch, useSelector } from 'react-redux';
import { useEquipos } from 'screens/equipos/useEquipos';
import {
  devicesActions,
  selectDeviceSelected,
  selectFechaSelected,
  selectMaxDate,
  selectMinDate,
} from 'store/devices/devicesSlice';
import styled from 'styled-components';
import tw from 'twin.macro';

export const LogoImg = styled.img`
  ${tw`w-auto h-full`}
`;

export default function ViewHeader({ title, action, dateSelector, maxDateRangeInDays = 31 }: ViewHeaderProps) {
  const dispatch = useDispatch();
  const deviceSelected = useSelector(selectDeviceSelected);
  const devicesList = useEquipos();
  const dateSelected = useSelector(selectFechaSelected);
  const minDateSelected = useSelector(selectMinDate);
  const maxDateSelected = useSelector(selectMaxDate);

  const { handleDeviceSelection } = useRouting();

  const onChangeValue = (value: string) => {
    const newDevice = devicesList?.find(({ serialNumber }) => serialNumber === value);
    if (newDevice) handleDeviceSelection(newDevice);
  };

  return (
    <div className="lg:flex lg:items-start lg:justify-between px-8 py-8">
      <div className="min-w-0 flex-1 mb-4">
        <TextHeader>{title}</TextHeader>
        <div className="mt-2 flex flex-col tablet:grid tablet:grid-cols-3 tablet:gap-4 items-center">
          {deviceSelected ? (
            <Select
              name="device"
              label="Equipo"
              options={devicesList.map(({ serialNumber, alias }) => ({
                name: `${serialNumber}${alias ? ` - ${alias}` : ''}`,
                value: serialNumber,
              }))}
              value={deviceSelected?.serialNumber}
              onChangeValue={onChangeValue}
              className="tablet:col-span-1"
            />
          ) : null}
          <div className="tablet:col-span-1 w-full">
            {dateSelector === 'single' && (
              <SelectFecha
                onSelect={(date: string) => dispatch(devicesActions.setDate(date))}
                fechaSelected={dateSelected}
              />
            )}
            {dateSelector === 'range' && (
              <SelectBetweenFechasOnePicker
                onSelectFechaInicio={(date: string) => dispatch(devicesActions.setMinDate(date))}
                onSelectFechaFin={(date: string) => dispatch(devicesActions.setMaxDate(date))}
                fechaInicio={minDateSelected}
                fechaFin={maxDateSelected}
                maxDateRangeInDays={maxDateRangeInDays}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex tablet:py-1">
        <span className="block">
          {action && (
            <ProtectedView active={action.isProtected}>
              <Button onClick={action.onClick} disabled={action.disabled} variant={'fill'}>
                {action.label}
              </Button>
            </ProtectedView>
          )}
        </span>
      </div>
    </div>
  );
}

interface ViewHeaderProps {
  title: string;
  dateSelector?: 'range' | 'single';
  maxDateRangeInDays?: number;
  action?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    isProtected?: boolean;
  };
}
