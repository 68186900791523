import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

export type ChartDataPoint = {
  ingredient: string;
  kgDownloaded: number;
};

export default function ConsumosPieChart({ data }: { data: ChartDataPoint[] }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart data={data}>
        <Pie
          data={data}
          dataKey="kgDownloaded"
          nameKey="ingredient"
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label={(entry) => entry.name}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#AF19FF',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
  '#FF1919',
  '#FF19C1',
  '#19FFC1',
  '#19FF19',
  '#C1FF19',
  '#FFC119',
];
