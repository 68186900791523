import { customBodyRenderNumberValue } from 'components/table/TableSubsum';

export const columnsTablaConsumosLote = [
  {
    name: 'ingredient',
    label: 'Ingrediente',
    options: {
      filter: false,
      sort: false,
    },
  },
/*   {
    name: 'percentage',
    label: '% Receta',
    options: {
      filter: false,
      sort: false,
    },
  }, */
/*   {
    name: 'kgLoaded',
    label: 'KG Cargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  }, */
  {
    name: 'kgDownloaded',
    label: 'KG Descargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
];
