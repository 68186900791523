import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ChipToggle from 'design-system/chip-toggle';
import ViewHeader from 'design-system/view-header';
import { useCalendarQueryDevicesFromSameStock } from 'hooks/useCalendarQuery';
import { useToggle } from 'hooks/useToggle';
import { useCallback } from 'react';
import { useGetDevicesLoadDataQuery } from 'store/devices/devicesApi';
import { isTrue } from 'utils/helpers/validation';
import parseFunction from './parser';

function HistoricoIngredientes() {
  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQueryDevicesFromSameStock(useGetDevicesLoadDataQuery);

  const [filterNotLoaded, toggleFilterNotLoaded] = useToggle(false);

  const customToolbar = useCallback(
    () => (
      <div className="inline-block">
        <ChipToggle
          label={`Filtrar no cargados`}
          onToggle={toggleFilterNotLoaded}
          toggleValue={isTrue(filterNotLoaded)}
          noBorder
        />
      </div>
    ),
    [filterNotLoaded, toggleFilterNotLoaded]
  );

  return (
    <>
      {isDeviceLoadDataFetching && <FullScreenLoader />}
      <ViewHeader title="Histórico de Ingredientes" dateSelector="range" maxDateRangeInDays={40} />
      <TableSubsumView
        title="Histórico de Ingredientes"
        data={deviceLoadData}
        columnsName={'columnsHistoricoIngredientes'}
        parseFunction={parseFunction}
        extraParseFunctionArgs={[filterNotLoaded]}
        tableProps={{
          withSelect: false,
          tableOptions: {
            pagination: false,
            customToolbar,
          },
        }}
      />
    </>
  );
}

export default HistoricoIngredientes;
