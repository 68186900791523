import { FullScreenLoader } from 'components/loader';
import ChipToggle from 'design-system/chip-toggle';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useToggle } from 'hooks/useToggle';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetDeviceLoadDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelectedStockIngredients, selectMaxDate, selectMinDate } from 'store/devices/devicesSlice';
import { isTrue } from 'utils/helpers/validation';
import parseFunction from './parser';

function StockHistorico() {
  const { columns, onColumnsViewChange } = useColumns('columnsStockHistorico');
  const stockIngredients = useSelector(selectDeviceSelectedStockIngredients);
  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQuery(useGetDeviceLoadDataQuery);

  const minDate = useSelector(selectMinDate);
  const maxDate = useSelector(selectMaxDate);
  const dateRangeSelected = moment(maxDate).diff(moment(minDate), 'days');

  const [filterNotLoaded, toggleFilterNotLoaded] = useToggle(false);

  const customToolbar = useCallback(
    () => (
      <div className="inline-block">
        <ChipToggle
          label={`Filtrar no cargados`}
          onToggle={toggleFilterNotLoaded}
          toggleValue={isTrue(filterNotLoaded)}
          noBorder
        />
      </div>
    ),
    [filterNotLoaded, toggleFilterNotLoaded]
  );

  const dataStockHistory = useMemo(
    () => parseFunction(dateRangeSelected, deviceLoadData, stockIngredients, filterNotLoaded),
    [deviceLoadData, stockIngredients, dateRangeSelected, filterNotLoaded]
  );

  return (
    <>
      {isDeviceLoadDataFetching && <FullScreenLoader />}
      <ViewHeader title="Histórico de Stock" dateSelector="range" maxDateRangeInDays={40} />
      <TableView
        title="Histórico de Stock"
        data={dataStockHistory}
        columns={columns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          pagination: false,
          filter: false,
          customToolbar,
        }}
      />
    </>
  );
}

export default StockHistorico;
