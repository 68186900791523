import { customBodyRenderDateTotalColumn, customBodyRenderSummedAttribute } from 'components/table/TableSubsum';
import { redondearDosDecimales } from 'utils/helpers/math';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Fragment } from 'react';

// Solo mostrar el total en la última fila si hay un filtro por lote
export const customBodyRenderOnlyIfLotFilter = (value, tableMeta) => {
  if (tableMeta?.rowIndex === tableMeta?.currentTableData?.length - 1) {
    const lotFilter = tableMeta?.tableState?.filterList?.[2];
    if (lotFilter && lotFilter.length === 1) return customBodyRenderSummedAttributePromedio(value, tableMeta);
    return;
  }

  return customBodyRenderSummedAttributePromedio(value, tableMeta);
};

// Custom body render para la tabla de historico de lotes
function customBodyRenderSummedAttributePromedio(value, tableMeta) {
  return Array.isArray(value) ? (
    <div className="custom-row-cell-two-values">
      <span>
        {tableMeta?.columnData?.label}
        <br /> Promedio Día
      </span>
      <span>
        <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-positive">
          <ArrowDropUpIcon />
          {redondearDosDecimales(value[1] || 0)}
        </span>
        {value[0] !== 0 && (
          <Fragment>
            <span className="custom-row-cell-two-values-text"> /</span>
            <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-negative">
              <ArrowDropDownIcon />
              {redondearDosDecimales(value[0] || 0)}
            </span>
          </Fragment>
        )}
      </span>
    </div>
  ) : (
    redondearDosDecimales(value)
  );
}

export const columnsHistoricoLotes = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateTotalColumn,
    },
  },
  {
    name: 'downloadGuide',
    label: 'Guía Descarga',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'lot',
    label: 'Lote',
    options: { filter: true, sort: true },
  },
  {
    name: 'lotKgMv',
    label: 'Kg Lote MV Teorico',
    extra: 'Kadssdadasdasdasdos MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'toDownload',
    label: 'Kg Descarga según Carga',
    extra: 'Kadssdadasdasdasdos MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'downloaded',
    label: 'Kg Descargados',
    extra: 'Kadssdadasdasdasdos MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'kgTeoricosMSGuia',
    label: 'Kg Teóricos MS Guía',
    extra: 'Kadssdadasdasdasdos MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'kgDescargadosMS',
    label: 'Kg Descargados MS',
    extra: 'Kadssdadasdasdasdos MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'porcentajeErrorCarga',
    label: '% Error según Carga',
    options: { filter: false, sort: false },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'elaborationPercentage',
    label: '% Elaboración',
    options: { filter: false, sort: false },
  },
  {
    name: 'headsCount',
    label: 'Cabezas',
    options: { filter: false, sort: false },
  },
  {
    name: 'consumoRealCab',
    label: 'Consumo Real Cab MV',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter,
    },
  },
  {
    name: 'consumoTeoricoCab',
    label: 'Consumo Teórico Cab MV',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter,
    },
  },
  {
    name: 'porcentajeAlimentacionReal',
    label: '% Alimentación Real',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter,
    },
  },
  {
    name: 'consumoTeoricoCabMs',
    label: 'Consumo Teórico Cab MS',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderOnlyIfLotFilter },
  },
  {
    name: 'consumoRealCabMs',
    label: 'Consumo Real Cab MS',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderOnlyIfLotFilter },
  },
  {
    name: 'priceConsumidosTotal',
    label: '$ Consumidos Total',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderSummedAttribute },
  },
  {
    name: 'priceConsumidosCab',
    label: '$ Consumidos Cab',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderSummedAttribute },
  },
];
