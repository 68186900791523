import { useMemo } from 'react';

type Column = {
  name: string;
  label: string;
  options?: { customBodyRender?: (value: any, tableMeta?: any) => any };
  [key: string]: any;
};

export default function LiteTable({
  title = '',
  data = [],
  columns = [],
}: {
  title?: string;
  data: any[];
  columns: Column[];
}) {
  const rows = useMemo(
    () =>
      data.map((dataRow, rowIndex) =>
        columns.map(({ name, options: { customBodyRender = (value: any) => value } = {} }) =>
          customBodyRender(dataRow[name], { tableData: data, rowIndex })
        )
      ),
    [columns, data]
  );

  return (
    <div className="lite-table-container">
      {!!title && <h4 className='py-2 text-base'>{title}</h4>}
      <table>
        <thead>
          <tr>
            {columns.map(({ label }, index) => (
              <th key={`${label}-${index}`}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr
              key={`${idx}`}
              className={`${data[idx].rowType === 'resultado' ? 'custom-row-subsuma-final' : ''}  ${
                data[idx].deleted ? 'custom-row-deleted' : ''
              }
            ${data[idx].nuevo ? 'custom-row-nuevo' : ''}`}
            >
              {row.map((value, index) => (
                <td key={`${value}-${index}`}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
