import { Close } from '@material-ui/icons';
import LiteTable from 'components/lite-table';
import { useColumns } from 'hooks/useColumns';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceSelected, selectDeviceSelectedStock } from 'store/devices/devicesSlice';
import { UnloadGuide } from 'utils/device/lot';
import { redondearDosDecimales } from 'utils/helpers/math';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';
import parseDataReceta, { RecipeIngredientFormatted } from '../../creador-recetas/tabla-receta/parser';
import parseDataCreadorDescargas, { UnloadGuideFormatted, UnloadGuideSummed } from '../tabla-descarga/parser';

const TablaRecetaPorLote = ({
  descarga,
  lotId,
  onClose,
}: {
  descarga: UnloadGuide;
  lotId: number;
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();
  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceV2 = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);
  const { columns: columnsCreadorDescarga } = useColumns('columnsTablaRecetaPorLote');

  const sipnStockSelected = useSelector(selectDeviceSelectedStock);
  const associatedRecipeData = deviceSelected?.recipes?.find((recipe) => recipe.id === descarga.associatedRecipe);

  const parsedDescarga = useMemo(
    () =>
      parseDataCreadorDescargas(
        {
          ...descarga,
          associatedRecipeData,
        },
        isDeviceV2
      ),
    [associatedRecipeData, descarga, isDeviceV2]
  );

  const parsedReceta = useMemo(
    () => (associatedRecipeData ? parseDataReceta(associatedRecipeData, sipnStockSelected?.ingredients) : []),
    [associatedRecipeData, sipnStockSelected?.ingredients]
  );

  // Get the selected lot data
  const isResultado = (lotId || 0) < 1;
  const parsedDescargaRow = parsedDescarga.find((row) =>
    isResultado ? row.rowType === 'resultado' : (row as UnloadGuideSummed).id === lotId
  );

  // Descarga row totals
  const descargaTotalMV = getDescargaMVTotal(parsedDescargaRow);
  const descargaTotalMS = getDescargaMSTotal(parsedDescargaRow);

  // Ingredients as percentage of the total recipe
  const recetaTotalMV = getRecetaMVTotal(parsedReceta);
  const recetaTotalMS = getRecetaMSTotal(parsedReceta);
  let ingredientesPercentage = parsedReceta
    .filter((row) => row.rowType === 'value')
    .map((row) => {
      const percentageMS = (row.kgMS as number) / recetaTotalMS;
      const percentageMV = (row.kgMV as number) / recetaTotalMV;

      return {
        ...row,
        percentageMS,
        percentageMV,
        kgMS: redondearDosDecimales(percentageMS * descargaTotalMS),
        kgMV: redondearDosDecimales(percentageMV * descargaTotalMV),
      };
    });

  const totalPercentageMS = ingredientesPercentage.reduce((acc, curr) => acc + (curr.percentageMS as number), 0);
  const totalPercentageMV = ingredientesPercentage.reduce((acc, curr) => acc + (curr.percentageMV as number), 0);
  ingredientesPercentage.push({
    kgMS: redondearDosDecimales(totalPercentageMS * descargaTotalMS),
    kgMV: redondearDosDecimales(totalPercentageMV * descargaTotalMV),
    percentageMS: totalPercentageMS,
    percentageMV: totalPercentageMV,
    rowType: 'resultado',
  } as any);

  return (
    <div className="relative w-full h-full">
      <button className="absolute top-0 right-0 p-2 text-xs text-white" onClick={onClose}>
        <div className="w-4 h-4 relative">
          <Close />
        </div>
      </button>
      <LiteTable
        title={`Receta Por Lote: ${isResultado ? 'Resultado' : (parsedDescargaRow as UnloadGuideSummed)?.name}`}
        data={ingredientesPercentage}
        columns={columnsCreadorDescarga}
      />
    </div>
  );
};

export default memo(TablaRecetaPorLote);

function getRecetaMVTotal(receta: RecipeIngredientFormatted[]): number {
  if (receta.length > 0 && receta[receta.length - 1].rowType === 'resultado') {
    let lastRow = receta[receta.length - 1];
    return Array.isArray(lastRow.kgMV) ? (lastRow.kgMV as Array<number>)[1] : (lastRow.kgMV as number);
  }
  return 0;
}

function getRecetaMSTotal(receta: RecipeIngredientFormatted[]): number {
  if (receta.length > 0 && receta[receta.length - 1].rowType === 'resultado') {
    let lastRow = receta[receta.length - 1];
    return Array.isArray(lastRow.kgMS) ? (lastRow.kgMS as Array<number>)[1] : (lastRow.kgMS as number);
  }
  return 0;
}

function getDescargaMVTotal(descargaRow?: UnloadGuideFormatted): number {
  return Array.isArray(descargaRow?.kgLotMV)
    ? (descargaRow?.kgLotMV as Array<number>)[1]
    : (descargaRow?.kgLotMV as number) || 0;
}

function getDescargaMSTotal(descargaRow?: UnloadGuideFormatted): number {
  return Array.isArray(descargaRow?.kgLot)
    ? (descargaRow?.kgLot as Array<number>)[1]
    : (descargaRow?.kgLot as number) || 0;
}
