import { customBodyRenderDate, customBodyRenderPercentage } from 'utils/helpers/body-renders';
import {
  customBodyRenderNumberValue,
  customBodyRenderSelectedColumn,
  customBodyRenderSummedAttribute,
} from 'components/table/TableSubsum';

export const columnsPesadas = [
  {
    name: 'isSelected',
    label: 'Sumar',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'ingredient',
    label: 'Ingrediente',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'toLoad',
    label: 'Kg Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'loaded',
    label: 'Kg Cargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'porcentajeKg',
    label: '%Kg',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'kgTeoricoMS',
    label: 'Kg Teorico MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'kgCargadosMS',
    label: 'Kg Cargados MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'recipe',
    label: 'Dieta',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'amount',
    label: 'Cantidad Cabezas / KG',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'price',
    label: 'Precio ($)',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'porcentajePrecio',
    label: 'Dif. en $',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'dryMatterPercentage',
    label: '%MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderPercentage,
    },
  },
];

export const columnsPesadasV5 = columnsPesadas.concat([
  {
    name: 'operationID',
    label: 'Operation ID',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'startAction',
    label: 'Accion Inicial',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'speedLoad',
    label: 'Velocidad de Carga',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'actualWeight',
    label: 'Peso Actual',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'currentIngredient',
    label: 'Ingrediente Actual',
    options: {
      filter: false,
      sort: false,
    },
  },
]);
