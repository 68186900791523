import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsDelpro = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'Numero del grupo',
    label: 'Numero del grupo',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Nombre del grupo',
    label: 'Nombre del grupo',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Contador de animales',
    label: 'Contador de animales',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Produccion total ayer',
    label: 'Produccion total ayer',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Promedio Produccion ayer',
    label: 'Promedio Produccion ayer',
    options: {
      filter: false,
      sort: false,
    },
  },
];
