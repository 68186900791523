import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQueryDevicesFromSameStock } from 'hooks/useCalendarQuery';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useGetDevicesDownloadDataQuery, useGetDevicesLoadDataQuery } from 'store/devices/devicesApi';
import { DeviceEventDownload, DeviceEventLoad } from 'utils/device/event/sip';
import { redondearDosDecimales } from 'utils/helpers/math';
import parseFunctionDescargas from '../descargas/parser';
import parseFunctionPesadas from '../pesadas/parser';
import { useToggle } from 'hooks/useToggle';
import ChipToggle from 'design-system/chip-toggle';
import { isTrue } from 'utils/helpers/validation';

export default function ReporteMixeros() {
  // Data Pesadas
  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQueryDevicesFromSameStock(useGetDevicesLoadDataQuery);

  // Data Descargas
  const { data: deviceDownloadData = [], isFetching: isDeviceDownloadDataFetching } =
    useCalendarQueryDevicesFromSameStock(useGetDevicesDownloadDataQuery);

  // Group deviceLoadData by user
  const groupedDataPesadasByUser = deviceLoadData.reduce((acc, curr) => {
    const user = curr.user;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push(curr);
    return acc;
  }, {} as { [key: string]: DeviceEventLoad[] });

  // Group deviceDownloadData by user
  const groupedDataDescargasByUser = deviceDownloadData.reduce((acc, curr) => {
    const user = curr.user;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push(curr);
    return acc;
  }, {} as { [key: string]: DeviceEventDownload[] });

  // Get a unique list of users from the keys of both groupedDataPesadasByUser and groupedDataDescargasByUser
  const users = Object.keys({ ...groupedDataPesadasByUser, ...groupedDataDescargasByUser });

  // Create one dictionary of users to {pesadasRiesgosas: [], pesadasComunes: [], descargas: []}
  const usersData = users.reduce((acc, curr) => {
    const pesadasRiesgosas = groupedDataPesadasByUser[curr]?.filter((pesada) => pesada.riskIng) || [];
    const pesadasComunes = groupedDataPesadasByUser[curr]?.filter((pesada) => !pesada.riskIng) || [];

    acc[curr] = {
      pesadasRiesgosas,
      pesadasComunes,
      descargas: groupedDataDescargasByUser[curr] || [],
    };
    return acc;
  }, {} as { [key: string]: { pesadasRiesgosas: DeviceEventLoad[]; pesadasComunes: DeviceEventLoad[]; descargas: DeviceEventDownload[] } });

  const isLoading = isDeviceLoadDataFetching || isDeviceDownloadDataFetching;

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ViewHeader title="Reporte Mixeros" dateSelector="range" />
      <div className="content-view px-8 py-8 flex flex-col">
        {Object.entries(usersData).map(([user, userData], indexDateGroup) => (
          <Fragment key={indexDateGroup}>
            <h3 className="text-lg font-medium leading-6 text-white pt-6 pb-4">{user}</h3>
            <PesadasAccordion data={userData.pesadasRiesgosas} isRisk />
            <PesadasAccordion data={userData.pesadasComunes} />
            <DescargasAccordion data={userData.descargas} />
          </Fragment>
        ))}
      </div>
    </>
  );
}

const PesadasAccordion = ({ data, isRisk }: { data: DeviceEventLoad[]; isRisk?: boolean }) => {
  const [diferenciaFilter, setDiferenciaFilter] = useState(0);

  // Filtro no cargados
  const [filterNotLoaded, toggleFilterNotLoaded] = useToggle(true);

  const filteredData = useMemo(() => {
    if (diferenciaFilter === 0) return data;
    return data.filter((row) => {
      const porcentajeKg = redondearDosDecimales((row.loaded * 100) / row.toLoad - 100);
      const diferenciaKg = row.loaded - row.toLoad;
      return (
        (!filterNotLoaded || porcentajeKg > -95) &&
        Math.abs(diferenciaKg) > Math.abs(diferenciaFilter) &&
        Math.abs(porcentajeKg) > 5
      );
    });
  }, [data, diferenciaFilter, filterNotLoaded]);

  const parsedData = useMemo(() => parseFunctionPesadas(filteredData, {}, false), [filteredData]);

  const lastRow = parsedData[0]?.[parsedData[0].length - 1];
  const totalToLoad = (Array.isArray(lastRow?.toLoad) ? lastRow?.toLoad[1] + lastRow?.toLoad[0] : lastRow?.toLoad) || 0;
  const totalLoaded = (Array.isArray(lastRow?.loaded) ? lastRow?.loaded[1] + lastRow?.loaded[0] : lastRow?.loaded) || 0;
  const porcentaje = redondearDosDecimales((totalLoaded * 100) / totalToLoad) || 0;

  const customToolbar = useCallback(
    () => (
      <div className="inline-block">
        <ChipToggle
          label={`Filtrar no cargados`}
          onToggle={toggleFilterNotLoaded}
          toggleValue={isTrue(filterNotLoaded)}
          noBorder
        />
      </div>
    ),
    [filterNotLoaded, toggleFilterNotLoaded]
  );

  return (
    <Accordion
      style={{
        backgroundColor: 'transparent',
        border: 'rgba(255, 255, 255, 0.1) 1px solid',
        boxShadow: '0px 0px 1px rgba(255,255,255,0.25)',
        color: 'white',
      }}
    >
      <AccordionSummary
        expandIcon={<FiChevronDown fontSize="32px" />}
        style={{
          color: 'white !important',
        }}
      >
        <div className="flex flex-col gap-4 py-4">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-medium leading-6 text-white">
              Ingredientes {isRisk ? 'Riesgosos' : 'Comunes'}
            </h3>
            <h3 className="text-lg font-medium leading-6 text-white px-2 border border-haasten-light rounded-full bg-haasten-light/30">
              {porcentaje.toLocaleString('es-AR')}%
            </h3>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-base font-medium leading-6 text-neutral-200">
              Kg Teórico: {totalToLoad.toLocaleString('es-AR')}Kg
            </h3>
            <h3 className="text-base font-medium leading-6 text-neutral-200">
              Kg Cargados: {totalLoaded.toLocaleString('es-AR')}Kg
            </h3>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col gap-4 w-full">
          <div className="w-full sm:w-96 flex px-8">
            <Input
              label="Diferencia Kg"
              placeholder="Diferencia Kg"
              defaultValue={diferenciaFilter}
              onBlur={(value: string) => {
                if (Number(value) !== diferenciaFilter) setDiferenciaFilter(Number(value));
              }}
              type={'number'}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.stopPropagation();
              }}
              min={0}
            />
          </div>
          <div className="w-full overflow-hidden relative">
            <TableSubsumView
              title={`Reporte Mixeros - ${isRisk ? 'Riesgosos' : 'Comunes'}`}
              data={filteredData}
              columnsName="columnsPesadas"
              parseFunction={parseFunctionPesadas}
              extraParseFunctionArgs={[filterNotLoaded]}
              tableProps={{
                tableOptions: {
                  customToolbar,
                },
              }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const DescargasAccordion = ({ data }: { data: DeviceEventDownload[] }) => {
  // Filtro no cargados
  const [filterNotLoaded, toggleFilterNotLoaded] = useToggle(true);

  const customToolbar = useCallback(
    () => (
      <div className="inline-block">
        <ChipToggle
          label={`Filtrar no cargados`}
          onToggle={toggleFilterNotLoaded}
          toggleValue={isTrue(filterNotLoaded)}
          noBorder
        />
      </div>
    ),
    [filterNotLoaded, toggleFilterNotLoaded]
  );

  const [diferenciaFilter, setDiferenciaFilter] = useState(0);

  const filteredData = useMemo(() => {
    if (diferenciaFilter === 0) return data;
    return data.filter((row) => {
      const porcentajeKg = redondearDosDecimales((row.downloaded * 100) / row.toDownload - 100);
      const diferenciaKg = row.toDownload - row.downloaded;
      return (
        (!filterNotLoaded || porcentajeKg > -95) &&
        Math.abs(diferenciaKg) > Math.abs(diferenciaFilter) &&
        Math.abs(porcentajeKg) > 5
      );
    });
  }, [data, diferenciaFilter, filterNotLoaded]);

  const parsedData = useMemo(
    () => parseFunctionDescargas(filteredData, {}, filterNotLoaded),
    [filteredData, filterNotLoaded]
  );

  const lastRow = parsedData[0]?.[parsedData[0].length - 1];
  const totalToDownload =
    (Array.isArray(lastRow?.toDownload) ? lastRow?.toDownload[1] + lastRow?.toDownload[0] : lastRow?.toDownload) || 0;
  const totalDownloaded =
    (Array.isArray(lastRow?.downloaded) ? lastRow?.downloaded[1] + lastRow?.downloaded[0] : lastRow?.downloaded) || 0;
  const porcentaje = redondearDosDecimales((totalDownloaded * 100) / totalToDownload) || 0;

  return (
    <Accordion
      style={{
        backgroundColor: 'transparent',
        border: 'rgba(255, 255, 255, 0.1) 1px solid',
        boxShadow: '0px 0px 1px rgba(255,255,255,0.25)',
        color: 'white',
      }}
    >
      <AccordionSummary
        expandIcon={<FiChevronDown fontSize="32px" />}
        style={{
          color: 'white !important',
        }}
      >
        <div className="flex flex-col gap-4 py-4">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-medium leading-6 text-white">Descargas</h3>
            <h3 className="text-lg font-medium leading-6 text-white px-2 border border-haasten-light rounded-full bg-haasten-light/30">
              {porcentaje.toLocaleString('es-AR')}%
            </h3>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-base font-medium leading-6 text-neutral-200">
              Kg Teórico: {totalToDownload.toLocaleString('es-AR')}Kg
            </h3>
            <h3 className="text-base font-medium leading-6 text-neutral-200">
              Kg Descargados: {totalDownloaded.toLocaleString('es-AR')}Kg
            </h3>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col gap-4 w-full">
          <div className="w-full sm:w-96 flex px-8">
            <Input
              label="Diferencia Kg"
              placeholder="Diferencia Kg"
              defaultValue={diferenciaFilter}
              onBlur={(value: string) => {
                if (Number(value) !== diferenciaFilter) setDiferenciaFilter(Number(value));
              }}
              type={'number'}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.stopPropagation();
              }}
              min={0}
            />
          </div>
          <div className="w-full overflow-hidden relative">
            <TableSubsumView
              title={`Reporte Mixeros - Descargas`}
              data={filteredData}
              columnsName="columnsDescargas"
              parseFunction={parseFunctionDescargas}
              extraParseFunctionArgs={[filterNotLoaded]}
              tableProps={{
                tableOptions: {
                  customToolbar,
                },
              }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
