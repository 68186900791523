import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { selectDevices } from 'store/config/configSlice';
import { devicesActions, selectDeviceSelected } from 'store/devices/devicesSlice';
import { ROUTE, ROUTES, ROUTE_GROUP_CONFIGURACION, ROUTE_GROUP_HOME } from 'utils/constants/routes';
import { ROUTES_ADMIN } from 'utils/constants/routes/routes-admin';
import { isRouteValid } from 'utils/constants/routes/utils';
import { Device, DeviceItem } from 'utils/device';
import { isDeviceVersionAtLeast } from 'utils/helpers/validation';
import { useAuth } from './useAuth';
import { ROUTE_PATH } from 'utils/constants/routes/types';

export const useRouting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loggedUser: { token, user, isError, isUserAdmin },
  } = useAuth();
  let { pathname } = useLocation();
  let { serialNumber } = useParams<{ serialNumber: string }>();
  const devices = useSelector(selectDevices);
  const deviceSelected = useSelector(selectDeviceSelected);

  const selectDevice = useCallback(
    (device: DeviceItem | null) => {
      dispatch(devicesActions.setDeviceSelected(device));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isRouteValid(pathname)) history.push('/');
    else {
      if (pathname === '/login' && !isError && token && user?.username) {
        history.push('/');
      } else if (isUserAdmin === false && pathname !== '/' && pathname !== '/configuracion' && pathname !== '/login') {
        const [, , pathDevice] = pathname.split('/') || [];
        if (!pathDevice) history.push('/');
        if (devices?.length && !deviceSelected && serialNumber) {
          const equipoPreSelected = devices.find((device: Device) => device.serialNumber === serialNumber);
          if (equipoPreSelected) selectDevice(equipoPreSelected);
          else history.push('/');
        }
      }
    }
  }, [
    deviceSelected,
    devices,
    history,
    serialNumber,
    pathname,
    selectDevice,
    isError,
    token,
    user?.username,
    isUserAdmin,
  ]);

  const routes = useMemo(
    () =>
      (isUserAdmin
        ? ROUTES_ADMIN
        : ROUTES.filter(
            ({ subgroup, devices: routeDevices }) =>
              subgroup === ROUTE_GROUP_HOME ||
              subgroup === ROUTE_GROUP_CONFIGURACION ||
              devices?.find(({ type }) => routeDevices.includes(type))
          )
      ).map((route: ROUTE) => {
        const equipoToSelect =
          deviceSelected?.serialNumber && route.devices.includes(deviceSelected?.type)
            ? deviceSelected
            : devices?.find(({ type }) => route.devices.includes(type));

        const hasAfimilk =
          Boolean(equipoToSelect?.associatedMilker) && equipoToSelect?.associatedMilker?.type === 'AFIMILKSIPN';
        const hasDelpro =
          Boolean(equipoToSelect?.associatedMilker) && equipoToSelect?.associatedMilker?.type === 'DELPROSIPN';

        return {
          ...route,
          links: route.links
            .filter(
              (link) => !link.minVersion || (equipoToSelect && isDeviceVersionAtLeast(link.minVersion, equipoToSelect))
            )
            // If the link is user restricted, filter it if the user is not in the list
            .filter(
              (link) =>
                (!link.userRestricted?.length || link.userRestricted.includes(user?.username)) &&
                (hasAfimilk || link.link !== ROUTE_PATH.AFIMILK) &&
                (hasDelpro || link.link !== ROUTE_PATH.DELPRO)
            )
            .map((link: any) =>
              !route.devices?.length // link.link === "/"
                ? {
                    ...link,
                    onClick: () => {
                      selectDevice(null);
                    },
                  }
                : {
                    ...link,
                    link: `${link.link}/${equipoToSelect?.serialNumber}`,
                    equipoToSelect: equipoToSelect,
                    onClick: () => {
                      selectDevice(equipoToSelect || null);
                    },
                  }
            ),
        };
      }),
    [isUserAdmin, devices, deviceSelected, user?.username, selectDevice]
  );

  const handleDeviceSelection = (device: DeviceItem) => {
    selectDevice(device);
    history.push(device.link || '');
  };

  return {
    serialNumber,
    routes,
    handleDeviceSelection,
    pathname,
  };
};
