import { customBodyRenderSelectedColumn } from 'components/table/TableSubsum';

export const columnsTablaRecetaPorLote = [
  {
    name: 'orden',
    label: 'Orden',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'name',
    label: 'Alimento',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'kgMS',
    label: 'Kg/CAB - MS',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgMV',
    label: 'Kg/CAB - MV',
    options: { filter: false, sort: false },
  },
];
