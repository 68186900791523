import Table from 'components/table/Table';
import Card from 'design-system/card';
import { useColumns } from 'hooks/useColumns';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeviceEventCas, groupCasDataBySilosAndCleanFalsePositives } from 'utils/device/event/cas';

export function CasHistoricoTabla({ deviceData }: { deviceData: DeviceEventCas[] }) {
  const { columns, onColumnsViewChange } = useColumns('columnsCasHistorico');

  const { watch } = useFormContext();
  const [silo] = watch(['silo']);

  const dataCasHistorico = useMemo(
    () => groupCasDataBySilosAndCleanFalsePositives(deviceData as unknown as DeviceEventCas[])?.[silo] || [],
    [deviceData, silo]
  );

  const totalKgConsumidos = useMemo(
    () =>
      dataCasHistorico.reduce((acc, item) => {
        const kgConsumidos = item.kgConsumidos || 0;
        if (kgConsumidos > 0) return acc + kgConsumidos;
        else return acc;
      }, 0),
    [dataCasHistorico]
  );

  const totalKgCargados = useMemo(
    () =>
      dataCasHistorico.reduce((acc, item) => {
        const kgConsumidos = item.kgConsumidos || 0;
        if (kgConsumidos < 0) return acc + kgConsumidos;
        else return acc;
      }, 0),
    [dataCasHistorico]
  );

  return (
    <div className="px-8 py-8">
      <Card>
        <div className="border-b border-b-white/20">
          <Table
            title="Histórico de CAS"
            data={dataCasHistorico}
            columns={columns}
            onColumnViewChange={onColumnsViewChange}
            options={{
              pagination: false,
              filter: true,
            }}
          />
        </div>
        <div className="flex flex-col gap-3 p-8">
          <h3 className="text-xl font-bold leading-6">Total: {totalKgConsumidos} Kg consumidos</h3>
          <h3 className="text-xl font-bold leading-6">Total: {Math.abs(totalKgCargados)} Kg ingreso stock</h3>
        </div>
      </Card>
    </div>
  );
}
