import Button from 'components/button';
import { ProtectedView } from 'components/protected-view';
import Card from 'design-system/card';
import { ReactNode } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export const FormCardIcon = styled.div`
  ${tw`w-9 h-9 rounded-md bg-haasten-800 flex justify-center items-center text-sm`}
`;

export default function FormCard({
  title,
  subtitle,
  items,
  onCancel,
  onSave,
  cancelLabel = 'Cancelar',
  saveLabel = 'Guardar cambios',
  isCancelDisabled,
  isSaveDisabled,
  isCancelProtected,
  isSaveProtected,
  icon,
}: FormCardProps) {
  return (
    <Card title={title} subtitle={subtitle} icon={icon}>
      <div>
        <dl>
          {items?.map(({ label, value }, index) => (
            <div
              key={`${label}-${index}`}
              className={`${
                index & 1 ? 'bg-background-black' : 'bg-neutral-900'
              } px-4 py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-6 items-center`}
            >
              {label && <dt className="text-sm font-medium ">{label}</dt>}
              <dd className={`mt-1 text-sm ${label ? 'tablet:col-span-2' : 'tablet:col-span-full'} tablet:mt-0`}>
                {value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      {(onSave || onCancel) && (
        <div
          className={`${
            items.length & 1 ? 'bg-background-black' : 'bg-neutral-900'
          } px-4 py-3 justify-end flex gap-4 sm:px-6`}
        >
          {onCancel && (
            <ProtectedView active={isCancelProtected}>
              <Button variant="text-danger" onClick={onCancel} disabled={isCancelDisabled}>
                {cancelLabel}
              </Button>
            </ProtectedView>
          )}
          {onSave && (
            <ProtectedView active={isSaveProtected}>
              <Button variant="fill" onClick={onSave} disabled={isSaveDisabled}>
                {saveLabel}
              </Button>
            </ProtectedView>
          )}
        </div>
      )}
    </Card>
  );
}

interface FormCardProps {
  title?: string;
  subtitle?: string;
  items: FormItem[];
  onCancel?: () => any;
  onSave?: () => any;
  cancelLabel?: string;
  saveLabel?: string;
  isCancelDisabled?: boolean;
  isSaveDisabled?: boolean;
  icon?: ReactNode;
  isCancelProtected?: boolean;
  isSaveProtected?: boolean;
}

interface FormItem {
  label?: string;
  value: string | ReactNode;
}
