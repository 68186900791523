import { DeviceDataEvent, DeviceEventDelpro, filterAndSortyByDelpro } from 'utils/device/event/sip';

type FlatDeviceEventDelpro = DeviceDataEvent & DeviceEventDelpro['data'];

export default function parseDelpro(data: DeviceDataEvent[]): FlatDeviceEventDelpro[] {
  return filterAndSortyByDelpro(data).flatMap((event) => {
    const { data, ...baseProperties } = event;

    return data.map((delpro) => {
      return {
        ...baseProperties,
        ...delpro,
      } as unknown as FlatDeviceEventDelpro;
    });
  });
}
